import React, { Suspense, useEffect } from "react";
import * as modules from "./modules";
// import { redirectSdkUri } from "./modules/AuthRedirect";
import { HashRouter, useRoutes, BrowserRouter } from "react-router-dom";
// import WebflowModule from "./components/Webflow/components/WebflowModule";
import { Service } from "@big-proxy/sdk";
import packageJson from "../package.json";
import { AuthProvider } from "src/components";

const modules_list = Object.values(modules);

// const Routes = () => useRoutes([...modules_list, WebflowModule]);
const Routes = () => useRoutes(modules_list);


function Application() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log({ version: packageJson.version });
    // Service.isLogined(redirectSdkUri);
  }, []);

  return (
    <Suspense fallback={"ooooops"}>
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default Application;
