/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from "react";
import Modal from "../forms/Modal";

type IOptions = {
  modalComponent?: typeof Modal | typeof Modal.Headered;
  modalProps?: Record<string, any>;
};

const defaultOptions: IOptions = {
  modalComponent: Modal.Headered,
  modalProps: {},
};

export const useModal = <P extends object>(
  Component: React.FC<P>,
  {
    modalComponent: ModalComponent = Modal.Headered,
    modalProps,
  }: IOptions = defaultOptions
) => {
  const [props, setProps] = useState<
    React.ComponentProps<typeof Component> | undefined
  >(undefined);

  const onOpen = useCallback(
    (prop: React.ComponentProps<typeof Component>) => setProps(prop),
    [setProps]
  );

  const onClose = useCallback(() => setProps(undefined), [setProps]);

  const fragment = useMemo(
    () => (
      <ModalComponent isVisible={!!props} onClose={onClose} {...modalProps}>
        <Component {...(props as React.ComponentProps<typeof Component>)} />
      </ModalComponent>
    ),
    [props, onClose, Component]
  );

  return [fragment, onOpen, onClose] as const;
};

export default useModal;
