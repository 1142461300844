export type IBillingDetails = {
  billing_details: {
    name: string;
    address: {
      city: string;
      country: string;
      line1: string;
      line2?: string;
      postal_code: string;
    };
  };
};

export const intialState = {
  billing_details: {
    name: "",
    address: {
      city: "",
      country: "US",
      line1: "",
      line2: "",
      postal_code: "",
    },
  },
};
