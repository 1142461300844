import { useResource } from "./useResource";
import { country_language } from "src/service";

export type ILanguageResource = { code: string; name: string };

export const useLanguagesResource = () => {
  return useResource<ILanguageResource>("languages-resource", async () => {
    const { data } = await country_language.getLanguages();
    return { data: data.message };
  });
};
