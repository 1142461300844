/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  ChangeEventHandler,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { useDimensions, useToggle } from "../../hooks";
import Input from "../Input";
import Button from "../../forms/Button";
import { OptionsMulti } from "./Options";
import Tags from "./Tags";
import * as utils from "./utils";
import { createPortal } from "react-dom";

import styles from "./select.multi.module.scss";

const EMPTY_ARRAY: any[] = [];

export interface ISelectProps {
  value: any[];
  options: any[];
  tagCount?: number;
  boundaries?: HTMLElement | null;
  placeholder?: HTMLInputElement["placeholder"];
  disabled?: HTMLInputElement["disabled"];
  name?: HTMLInputElement["name"];
  onChange: ChangeEventHandler<HTMLInputElement>;
  onInputChange?: ChangeEventHandler<HTMLInputElement>;
  idSelector?: (entity: any) => unknown;
  valueSelector?: (entity: any) => string;
  renderOption?: React.ComponentProps<typeof OptionsMulti>["renderOption"];
  filterSelector?: (
    entity: any,
    filterQuery: string,
    valueSelector: ISelectProps["valueSelector"]
  ) => boolean;
  renderTag?: React.ComponentProps<typeof Tags>["renderTag"];
  creatable?: boolean;
  creatableSelector?: (query: string) => any;
}

const MultiSelect = React.forwardRef<HTMLInputElement, ISelectProps>(
  (
    {
      tagCount = Number.MAX_SAFE_INTEGER,
      value = EMPTY_ARRAY,
      name,
      disabled,
      options,
      placeholder,
      onChange,
      onInputChange,
      idSelector = utils.defaultIdSelector,
      valueSelector = utils.defaultValueSelector,
      filterSelector = utils.defaultFilterSelector,
      renderOption,
      renderTag = utils.defaultRenderTag,
      creatable,
      boundaries,
      creatableSelector = utils.defaultCreatableSelector,
    },
    ref
  ) => {
    const _inputRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLInputElement>(null);
    const inputRef = (ref || _inputRef) as RefObject<HTMLInputElement>;

    const onClose = useCallback(() => {
      onToggleVisible.off();
      setIsFocused.off();
      setFilterQuery("");
    }, []);

    const valueRef = useRef<any[]>([]);
    valueRef.current = value;

    const [isVisible, onToggleVisible] = useToggle();
    const [isFocused, setIsFocused] = useToggle(false);
    const [filterQuery, setFilterQuery] = useState("");

    const [refDimensions, bounds] = useDimensions(onToggleVisible.off);

    const filteredOptions = useMemo(() => {
      const filterFn = (option: any) =>
        filterSelector(option, filterQuery, valueSelector);
      return options.filter(filterFn);
    }, [filterQuery, options, filterSelector]);

    const onChangeHandler = useCallback(
      (_value: any) => {
        const id = idSelector(_value);
        const title = valueSelector(_value);
        const ids = valueRef.current.includes(id)
          ? valueRef.current.filter((item: any) => item !== id)
          : [...valueRef.current, id];

        setFilterQuery("");
        onChange({
          target: { value: ids, name, title, id },
        } as unknown as ChangeEvent<HTMLInputElement>);
      },
      [onChange, name, idSelector, inputRef]
    );

    const onChangeInputHandler: ChangeEventHandler<HTMLInputElement> =
      useCallback(
        ({ target: { value, name } }) => {
          if (onInputChange) {
            onInputChange({
              target: { value, name },
            } as ChangeEvent<HTMLInputElement>);
          }
          setFilterQuery(value);
        },
        [onInputChange]
      );

    const onFocusHandler = useCallback(() => {
      onToggleVisible.on();
      setIsFocused.on();
      inputRef?.current?.focus();
    }, []);

    const selectedItems = useMemo(
      () => options.filter((id: any) => value.includes(idSelector(id))),
      [options, idSelector, value]
    );

    return (
      <>
        <div
          className={cx(styles.control_container, {
            [styles.disabled]: disabled,
          })}
          ref={refDimensions}
        >
          <div className={styles.control} data-testid="input-holder">
            <Tags
              disabled={disabled}
              onChange={onChangeHandler}
              valueSelector={valueSelector}
              idSelector={idSelector}
              tagsCount={tagCount}
              items={selectedItems}
              renderTag={renderTag}
            />
            <Input
              ref={inputRef}
              name={name}
              value={isFocused ? filterQuery : ""}
              onChange={onChangeInputHandler}
              disabled={disabled}
              placeholder={placeholder}
              className={styles.input}
              onFocus={onFocusHandler}
            />
          </div>
          <Button.Icon
            view={Button.Icon.views.FULL_TRIANGLE_ARROW}
            onClick={onFocusHandler}
            disabled={disabled}
            className={cx(styles.triangle_icon, {
              [styles.open]: isVisible && !disabled,
            })}
          />
        </div>
        {isVisible &&
          !disabled &&
          createPortal(
            <OptionsMulti
              inputRef={inputRef}
              optionsRef={optionsRef}
              onClose={onClose}
              renderOption={renderOption}
              key="multi-options-container"
              bounds={bounds}
              value={value}
              options={
                creatable && filterQuery && filteredOptions.length === 0
                  ? [creatableSelector(filterQuery)]
                  : filteredOptions
              }
              onChange={onChangeHandler}
              valueSelector={valueSelector}
              idSelector={idSelector}
            />,
            boundaries || document.body
          )}
      </>
    );
  }
);

MultiSelect.displayName = "MultiSelect";

export default MultiSelect;
