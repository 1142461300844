/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from "react";
import styles from "./Tags.module.scss";

interface ITags {
  items: any[];
  tagsCount: number;
  onChange: (entity: any) => void;
  valueSelector: (entity: any) => string;
  idSelector: (entity: any) => unknown;
  disabled?: boolean;
  renderTag: (
    item: any,
    onChangeHandler: (e: any) => void,
    disabled: boolean,
    valueSelector: ITags["valueSelector"],
    idSelector: ITags["idSelector"]
  ) => React.ReactElement;
}

const Tags: FC<ITags> = ({
  valueSelector,
  idSelector,
  onChange,
  items,
  renderTag,
  tagsCount,
  disabled = false,
}) => {
  return (
    <>
      {items.slice(0, tagsCount).map((item: any) => {
        return renderTag?.(item, onChange, disabled, valueSelector, idSelector);
      })}
      {tagsCount < items.length && (
        <div className={styles.dots}>
          <span>...</span>
        </div>
      )}
    </>
  );
};

export default memo(Tags);
