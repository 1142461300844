/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from "react";
import Option from "../Option";
import type { IOptionsProps } from "./Options";
import { useArrowsKeyPress } from "../useArrowsKeyPress";
import { useClickOutside } from "../../../hooks";

import styles from "./Options.module.scss";

export type IOptionsMultiProps = Omit<IOptionsProps, "value"> & {
  value: any[];
  bounds: DOMRect;
};

const defaultOptionComponent: IOptionsMultiProps["renderOption"] = ({
  id,
  isSelected,
  isHighlighted,
  option,
  onChange,
  valueSelector,
}) => (
  <Option
    id={id}
    key={id as string}
    option={option}
    isSelected={isSelected}
    isHighlighted={isHighlighted}
    valueSelector={valueSelector}
    onChange={onChange}
  />
);

const OptionsMulti: FC<IOptionsMultiProps> = ({
  value,
  options,
  bounds,
  onChange,
  valueSelector,
  idSelector,
  inputRef,
  optionsRef,
  onClose,
  renderOption = defaultOptionComponent,
}) => {
  const [position] = useArrowsKeyPress(
    optionsRef,
    options,
    idSelector,
    onChange
  );

  useClickOutside(optionsRef, onClose, [inputRef]);

  return (
    <div
      className={styles.options}
      style={{
        top: bounds.top + bounds.height,
        width: bounds.width,
        left: bounds.left,
      }}
      key="options-container-positioner"
      data-testid="options-container"
      ref={optionsRef}
    >
      {options.map((option: any, index) => {
        const id = idSelector(option);
        return renderOption?.({
          id,
          isSelected: value.includes(id),
          isHighlighted: position === index,
          option,
          onChange,
          valueSelector,
        });
      })}
    </div>
  );
};

export default memo(OptionsMulti);
