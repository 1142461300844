import { env, ls, routing } from "src/utils";

export const getDefaultGetPurchaseParams = () => ({
  price: "",
  projectId: env.BIG_PROXY_PROJECT_ID,
  language: "en",
  successUrl: window.location.origin + "/#/d-thank-you-page",
  cancelUrl: window.location.href,
  errorUrl: env.BIG_PROXY_CLIENT_URL + "/something_went_wrong",
  rejectUrl: window.location.origin + "/#" + routing.subscribe,
  mode: "s",
  quantity: 1,
  clientReferenceId: "",
  subscriptionId: "",
  priceId: "",
  returnUrl: window.location.origin + "/#" + routing.subscribe,
});

export const getPurchaseUrl = (
  _params: {
    projectId?: string | number;
    price?: string;
    language?: string;
    mode?: string;
    quantity?: string | number;
    successUrl?: string;
    cancelUrl?: string;
    errorUrl?: string;
    rejectUrl?: string;
    clientReferenceId?: string;
  } = getDefaultGetPurchaseParams()
) => {
  const params = { ...getDefaultGetPurchaseParams(), ..._params };

  const commonSearchParams = new URLSearchParams({
    language: params.language,
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
  });

  const parameters: Record<string, string> = {
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
    price: params.price,
    mode: params.mode,
    q: params.quantity as string,
    sUrl: params.successUrl as string,
    cUrl: params.cancelUrl as string,
    eUrl: (params.errorUrl as string) + "?" + commonSearchParams,
    rUrl: params.rejectUrl as string,
  };

  if (!!params.clientReferenceId) {
    parameters.cliRef = params.clientReferenceId;
    parameters.cliLang = params.language as string;
  }

  const authToken = ls.token.get();
  if (!!authToken) {
    parameters.token = authToken;
    delete parameters.cliRef
  }

  const search = new URLSearchParams(parameters);

  return `${env.BIG_PROXY_SERVICE}/payments/checkout-session?${search}`;
};


export const addPaymentMethodUrl = (
  _params: {
    projectId?: string | number;
    returnUrl?: string;
    errorUrl?: string;
  } = getDefaultGetPurchaseParams()
) => {
  const params = { ...getDefaultGetPurchaseParams(), ..._params };
  const parameters: Record<string, string> = {
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
    rUrl: params.returnUrl as string,
    eUrl: params.errorUrl as string,
  };
  const authToken = ls.token.get();
  if (!!authToken) {
    parameters.token = authToken;
  }
  const search = new URLSearchParams(parameters);
  return `${env.BIG_PROXY_SERVICE}/portal/payment-method/create?${search}`;
};

export const cancelSubscriptionUrl = (_params: {
  subscriptionId?: string | number;
  projectId?: string | number;
  errorUrl?: string;
  returnUrl?: string;
}) => {
  const params = { ...getDefaultGetPurchaseParams(), ..._params };
  const parameters: Record<string, string> = {
    subscriptionId: params.subscriptionId as string,
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
    rUrl: params.returnUrl as string,
    eUrl: params.errorUrl as string,
  };
  const authToken = ls.token.get();
  if (!!authToken) {
    parameters.token = authToken;
  }
  const search = new URLSearchParams(parameters);
  return `${env.BIG_PROXY_SERVICE}/portal/subscription/cancel?${search}`;
};

export const upgradeSubscriptionUrl = (_params: {
  subscriptionId?: string | number;
  priceId?: string | number;
  projectId?: string | number;
  returnUrl?: string;
  errorUrl?: string;
}) => {
  const params = { ...getDefaultGetPurchaseParams(), ..._params };
  const parameters: Record<string, string> = {
    subscriptionId: params.subscriptionId as string,
    priceId: params.priceId as string,
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
    rUrl: params.returnUrl as string,
    eUrl: params.errorUrl as string,
  };
  const authToken = ls.token.get();
  if (!!authToken) {
    parameters.token = authToken;
  }
  const search = new URLSearchParams(parameters);
  return `${env.BIG_PROXY_SERVICE}/portal/subscription/upgrade?${search}`;
};

export const managePaymentMethodUrl = (_params: {
  projectId?: string | number;
  returnUrl?: string;
  errorUrl?: string;
}) => {
  const params = { ...getDefaultGetPurchaseParams(), ..._params };
  const parameters: Record<string, string> = {
    projectId: (params.projectId || env.BIG_PROXY_PROJECT_ID) as string,
    rUrl: params.returnUrl as string,
    eUrl: params.errorUrl as string,
  };
  const authToken = ls.token.get();

  if (!!authToken) {
    parameters.token = authToken;
  }

  const search = new URLSearchParams(parameters);
  return `${env.BIG_PROXY_SERVICE}/portal/billing?${search}`;
}; 