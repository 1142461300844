import React, { FC, useState, useEffect, useCallback } from "react";
import moment from "moment";
import Paginator from "./Paginator";
import { always } from "../../utils";
import { useClickOutsideRef } from "../../hooks/useClickOutside";
import cx from "classnames";

import styles from "./Picker.module.scss";
import paginatorStyles from "./Paginator.module.scss";

export type IPickerProps = {
  value: moment.Moment;
  dateFormat?: string;
  bounds: DOMRect;
  onChange?: (value: moment.Moment | string) => void;
  onClose: () => void;
  inputRef: React.RefObject<HTMLDivElement>;
};

const WEEKDAY_NAMES = moment.weekdays();
const MONTHS = moment.months();
const WEEKDAYS = WEEKDAY_NAMES.map((elem) => (
  <span key={elem}>{elem.slice(0, 1).toLowerCase()}</span>
));

const moveMonthForward = (currentDate: Array<number>) =>
  moment.utc(currentDate).add(1, "months").toArray();
const moveMonthBackward = (currentDate: Array<number>) =>
  moment.utc(currentDate).add(-1, "months").toArray();
const moveYearForward = (currentDate: Array<number>) =>
  moment.utc(currentDate).add(1, "years").toArray();
const moveYearBackward = (currentDate: Array<number>) =>
  moment.utc(currentDate).add(-1, "years").toArray();
const monthStart = (currentDate: Array<number>) =>
  WEEKDAY_NAMES.indexOf(
    moment.utc(currentDate).startOf("month").format("dddd")
  );

const getArrayDate = (value: moment.Moment) =>
  value.isValid() ? value.toArray() : moment.utc().toArray();

const Picker: FC<IPickerProps> = ({
  value,
  bounds,
  onChange = always.EMPTY_FUNC,
  onClose = always.EMPTY_FUNC,
  inputRef,
}) => {
  const [currentDate, setCurrentDate] = useState(getArrayDate(value));
  const [year, month] = currentDate;

  useEffect(() => {
    setCurrentDate(getArrayDate(value));
  }, [value]);

  const clickOutsideRef = useClickOutsideRef<HTMLDivElement, typeof onClose>(
    onClose,
    [inputRef]
  );

  const onMonthAfter = useCallback(
    () => setCurrentDate((_current) => moveMonthForward(_current)),
    [setCurrentDate]
  );
  const onMonthBefore = useCallback(
    () => setCurrentDate((_current) => moveMonthBackward(_current)),
    [setCurrentDate]
  );
  const onYearAfter = useCallback(
    () => setCurrentDate((_current) => moveYearForward(_current)),
    [setCurrentDate]
  );
  const onYearBefore = useCallback(
    () => setCurrentDate((_current) => moveYearBackward(_current)),
    [setCurrentDate]
  );
  const onDayClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const dateValueOf = Number((e.target as HTMLButtonElement).value);
      onChange(moment.utc(dateValueOf));
    },
    [onChange]
  );

  const days = Array.from(
    { length: moment.utc(currentDate).daysInMonth() },
    (_, i) => {
      return (
        <button
          className={styles.day}
          data-testid="day"
          key={`${currentDate.valueOf()}-${i}`}
          value={moment
            .utc(currentDate)
            .date(i + 1)
            .startOf("day")
            .valueOf()}
          onClick={onDayClick}
        >
          {i + 1}
        </button>
      );
    }
  );

  const emptyDays = Array.from({ length: monthStart(currentDate) }, (_, i) => (
    <span
      key={`${currentDate.valueOf()}-empty-${i}`}
      className={cx(styles.days, styles.empty)}
    />
  ));

  return (
    <div
      className={styles.container}
      ref={clickOutsideRef}
      style={{
        top: bounds.top + bounds.height,
        right: document.body.clientWidth - bounds.x - bounds.width,
      }}
      data-testid="picker-container"
    >
      <div className={styles.paginators}>
        <Paginator
          testId="month"
          value={MONTHS[month]}
          onClickLeftArrow={onMonthBefore}
          onClickRightArrow={onMonthAfter}
          className={paginatorStyles.month}
        />
        <Paginator
          testId="year"
          value={year}
          onClickLeftArrow={onYearBefore}
          onClickRightArrow={onYearAfter}
          className={paginatorStyles.year}
        />
      </div>
      <div className={styles.month}>
        <div className={styles.weekdays}>{WEEKDAYS}</div>
        <div className={styles.days} data-testid="days">
          {emptyDays}
          {days}
        </div>
      </div>
    </div>
  );
};

export default Picker;
