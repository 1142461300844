/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, ReactNode, useContext } from "react";
import { filteringContexts } from "../contexts";
import { ITemplate } from "../Template";

interface ISearchFilter extends Record<string, any> {
  template: ITemplate;
}

const SearchFilter = ({ template, ...rest }: ISearchFilter): ReactNode => {
  const { values, onChange } = useContext(filteringContexts);
  return template?.filterComponent ? (
    template.filterComponent({
      ...rest,
      name: template.valueKey,
      value: values[template.valueKey],
      onChange,
    })
  ) : (
    <></>
  );
};

export default memo(SearchFilter as FC<ISearchFilter>);
