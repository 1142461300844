import React, { lazy } from "react";

const Component = lazy(() => import("./AdminPanel"));

export default {
  path: "/admin/admin-panel",
  element: <Component />,
  children: [
    {
      path: "/admin/admin-panel/:tab",
      element: <Component />,
    },
  ],
};
