import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useToggle } from "../../hooks";
import { is } from "../../utils";
import Input, { IInputProps } from "./Input";

export type IInputNumber = Omit<IInputProps, "type"> & {
  max?: number;
  min?: number;
  precision?: number;
};

const InputNumber: FC<IInputNumber> = ({
  value = "",
  onChange,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  precision,
  ...props
}) => {
  const [isFocused, setIsFocused] = useToggle(false);
  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    if (!isFocused) {
      setInputValue(value);
    }
  }, [isFocused, value]);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const {
        target: { value: changedValue = "" },
      } = event;

      const isNumberByPattern = /^-?\d*[.,]?\d*$/gi.test(changedValue);

      if (isNumberByPattern || changedValue === "") {
        const [integer, ...decimals] = changedValue.trim().split(/[,.]/gi);
        const decimal = decimals.join("");

        const numberString = decimal ? `${integer}.${decimal}` : integer;
        let number = numberString?.length ? Number(numberString) : null;
        const isNumberValid = !Number.isNaN(number) && !is.isNull(number);

        if (is.isNumber(precision) && isNumberValid) {
          number = Number(number?.toFixed(precision));
        }

        if (number && is.isNumber(max) && max < number) {
          number = max;
        }

        if (number && is.isNumber(min) && min > number) {
          number = min;
        }

        onChange &&
          onChange({
            target: {
              name: event.target.name,
              value: isNumberValid ? number : null,
            },
          } as unknown as ChangeEvent<HTMLInputElement>);

        setInputValue(changedValue);
      }
    },
    [onChange]
  );

  return (
    <Input
      value={isFocused ? inputValue : value}
      onChange={onChangeHandler}
      onFocus={setIsFocused.on}
      onBlur={setIsFocused.off}
      {...props}
    />
  );
};

InputNumber.displayName = "Input.Number";

export default memo(InputNumber);
