import {FC, useCallback, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {Button, Icon, useModal} from "@package/components";
import Badge from "src/components/Badge";
import BasketModal from "./BasketModal";
import useBasketItemsResource from "./useBasketItemsResource";
import {ls, purchase} from "src/utils";
import {useSelector} from "react-redux";
import {authorization, settings} from "src/store";
import {Modal as ModalComponent, useToggle} from "@package/components";
import PaymentMethod from "src/components/PaymentMethod";

import styles from "./styles.module.scss";
import {EntitiesBasket} from "./entitesBasket";
import {useDeviceDetectionBasedOnResize, useEvent} from "@big-proxy/ui";
import {getPrices} from "../../../service/payment";

const URL_SEARCH_BASKET_KEY = "basket";

const Basket: FC = () => {
  const [querySearchParams, setQuerySearchParams] = useSearchParams();
  const {entities, onReload, onFilter, isLoaded} = useBasketItemsResource();
  const language = useSelector(settings.selectors.getLanguage);
  const device = useDeviceDetectionBasedOnResize();
  const [visiblePaymentMethods, setVisiblePaymentMethods] = useToggle(false);

  const [fragment, onOpen, _onClose] = useModal(BasketModal, {
    modalProps: {disableClickOutside: true},
    modalComponent: ModalComponent,
  });

  const isBasketOpened = querySearchParams.has(URL_SEARCH_BASKET_KEY);

  const onRemoveQueryBasket = useCallback(() => {
    if (isBasketOpened) {
      querySearchParams.delete(URL_SEARCH_BASKET_KEY);
      setQuerySearchParams(querySearchParams);
    }
  }, [querySearchParams, isBasketOpened]);

  const onCloseBasket = useCallback(() => {
    _onClose();
    onRemoveQueryBasket();
  }, [onRemoveQueryBasket, _onClose]);

  const isBasketVisible = !!entities.length;
  const user = useSelector(authorization.selectors.getUser);
  const userId = user.userId;

  useEffect(() => {
    const _userId = userId || ls.userId.get();

    if (!_userId) {
      return;
    }

    onFilter("userId", _userId);
    onReload();
  }, [userId]);

  const openCheckoutModal = useEvent(async () => {
    const _userId = userId || ls.userId.get();

    const ids = entities.map(({id}) => id);

    const {data: {message: {prices}}} = await getPrices();

    const priceId = prices?.find(({ productMetadata: {
      vendor,
      product,
      plan
      }
    }: never) => vendor === 'Admintech' && product === 'Document' && plan === 'Purchase')?.id;

    location.href = purchase.getPurchaseUrl({
      price: priceId,
      mode: "p",
      clientReferenceId: _userId,
      quantity: ids?.length,
      language: language.toLowerCase(),
      successUrl: `${location.origin}/#/${device !== "DESKTOP" ? "m" : "d"}-sdp-thank-you-page`,
      cancelUrl: `${location.origin}/#/?basket=`,
    });
  });

  const onClickBasket = useCallback(() => {
    return onOpen({
      onClose: onCloseBasket,
      onReloadBasketItems: onReload,
      openCheckoutModal,
    });
  }, [onCloseBasket, entities]);

  useEffect(() => {
    if (isBasketOpened && isLoaded) {
      onClickBasket();
    }
  }, [isBasketOpened, onClickBasket, isLoaded]);

  return isBasketVisible ? (
    <Badge count={entities.length}>
      <Button.Icon view={Icon.views.BASKET} onClick={onClickBasket} className={styles.icon} />
      <EntitiesBasket.Provider value={entities}>
      {fragment}
      <PaymentMethod
        action={'Change'}
        isVisible={visiblePaymentMethods}
        onClose={() => {
          setVisiblePaymentMethods.off()
          openCheckoutModal()
        }}
      />
      </EntitiesBasket.Provider>
    </Badge>
  ) : (
    <></>
  );
};

export default Basket;
