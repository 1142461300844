import React, { FC } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";

type IBadge = React.PropsWithChildren<{ count: number }>;

const Badge: FC<IBadge> = ({ children, count }) => {
  const isThankYouPage = document.location.hash.includes('/d-sdp-thank-you-page');
  return (
    <div className={cx(styles.badge, {
      [styles.isThankYouPage]: isThankYouPage,
    })}>
      <span className={styles.count}>{count}</span>
      {children}
    </div>
  );
};

export default Badge;
