import React, { FC, RefObject } from "react";
import { IInputProps } from "./Input";
import cx from "classnames";

import styles from "./input.area.module.scss";

export type ITextareaProps = Omit<IInputProps, "type" | "ref"> & {
  ref?: RefObject<HTMLTextAreaElement>;
  maxLength?: HTMLTextAreaElement["maxLength"];
  minLength?: HTMLTextAreaElement["minLength"];
  rows?: HTMLTextAreaElement["rows"];
};

const InputTextarea: FC<ITextareaProps> = ({
  maxLength,
  minLength,
  className,
  rows,
  ...props
}: ITextareaProps) => {
  return (
    <textarea
      data-testid="input-area"
      className={cx(styles.inputArea, className)}
      maxLength={maxLength}
      minLength={minLength}
      rows={rows}
      {...props}
    />
  );
};

InputTextarea.displayName = "Input.Area";

export default InputTextarea;
