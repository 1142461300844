import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { user as localStorageUser } from "src/utils/localStorage";

type IInitialState = {
  isAuthorized: boolean | null;
  user: Record<string, any>;
  subscription: Record<string, any>;
};

const initialState: IInitialState = {
  isAuthorized: null,
  user: {},
  subscription: {},
};

const settings = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<IInitialState["user"]>) {
      localStorageUser.set(
        JSON.stringify({
          firstName: action.payload.name,
          lastName: action.payload.surname,
          email: action.payload.email,
          language: action.payload.lang,
        })
      );
      return { ...state, user: action.payload };
    },
    setSubscription(state, action: PayloadAction<IInitialState["subscription"]>) {
      return { ...state, subscription: action.payload };
    },
    setIsAuthorized(state, action: PayloadAction<IInitialState["isAuthorized"]>) {
      return { ...state, isAuthorized: action.payload };
    },
    setSubscriptionNull(state) {
      state.subscription.planId = null;
    },
  },
});

export default settings;
