/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from "react";

type IOnViewportChange = (entry: IntersectionObserverEntry) => void;

export const useDimensions = <H extends HTMLElement = HTMLDivElement>(
  onViewportChange?: IOnViewportChange,
  ...deps: any[]
): [React.RefObject<H>, DOMRect] => {
  const [dimensions, setDimensions] = useState<DOMRect>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
  } as DOMRect);
  const ref = useRef<H>(null);

  const dimensionsRef = useRef<{
    dimensions: typeof dimensions;
    animationId: number;
  }>({ dimensions, animationId: 0 });
  dimensionsRef.current.dimensions = dimensions;

  const onResize = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect().toJSON();
      const isEqual =
        JSON.stringify(dimensionsRef.current.dimensions) ===
        JSON.stringify(rect);
      if (!isEqual) {
        setDimensions(rect);
      }
    }
  }, [ref, dimensionsRef]);

  const onIntersect: IntersectionObserverCallback = useCallback(
    ([entry]) => {
      if (entry.isIntersecting) {
        const onRequest = () => {
          onResize();
          dimensionsRef.current.animationId =
            window.requestAnimationFrame(onRequest);
        };

        onRequest();
      } else {
        window.cancelAnimationFrame(dimensionsRef.current.animationId);
      }
      onViewportChange?.(entry);
    },
    [ref, dimensionsRef]
  );

  const [observer] = useState<IntersectionObserver>(
    new IntersectionObserver(onIntersect)
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [observer, ref, ...deps]);

  return [ref, dimensions];
};

export default useDimensions;
