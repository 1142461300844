import React, { ReactNode } from "react";
import { Icon } from "@package/components";
import styles from "../CountryAndLanguage.module.scss";

export const countryFlags: Record<string, ReactNode> = {
  Portugal: <Icon view={Icon.views.PORTUGAL} className={styles.icon} />,
  Spain: <Icon view={Icon.views.SPAIN} className={styles.icon} />,
  France: <Icon view={Icon.views.F_FR} className={styles.icon} />,
  Germany: <Icon view={Icon.views.F_GER} className={styles.icon} />,
  Mexico: <Icon view={Icon.views.MEX} />,
  Italy: <Icon view={Icon.views.F_IT} className={styles.icon} />,
  "United Kingdom": <Icon view={Icon.views.UNITED_KINGDOM} className={styles.icon} />,
  USA: <Icon view={Icon.views.F_USA} className={styles.icon} />,
  Ireland: <Icon view={Icon.views.IRELAND} className={styles.icon} />,
  Canada: <Icon view={Icon.views.CANADA} className={styles.icon} />,
  Austria: <Icon view={Icon.views.AUSTRIA} className={styles.icon} />,
  Belgium: <Icon view={Icon.views.BELGIUM} className={styles.icon} />,
  Luxembourg: <Icon view={Icon.views.LUXEMBOURG} className={styles.icon} />,
  Switzerland: <Icon view={Icon.views.F_SW} className={styles.icon} />,
  Poland: <Icon view={Icon.views.POLAND} className={styles.icon} />,
  Brazil: <Icon view={Icon.views.BRAZIL} className={styles.icon} />,
  Australia: <Icon view={Icon.views.AUSTRALIA} className={styles.icon} />,
};
