export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const IS_TEST = process.env.NODE_ENV === "test";
export const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL as string;
export const SERVICE_URL = process.env.REACT_APP_SERVICE as string;
export const CONSTRUCTOR_URL = process.env.REACT_APP_CONSTRUCTOR as string;
export const LINKEDIN_URL = process.env.REACT_APP_LINKEDIN_URL as string;
export const MAIL_URL = process.env.REACT_APP_MAIL_URL as string;
export const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env
  .REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
export const REACT_APP_STRIPE_BP_PUBLISH_KEY = process.env
  .REACT_APP_STRIPE_BP_PUBLISH_KEY as string;
export const BIG_PROXY_SERVICE = process.env.REACT_APP_BIG_PROXY_SERVICE as string;
export const BIG_PROXY_CLIENT_URL = process.env.REACT_APP_BIG_PROXY_CLIENT_URL as string;
export const BIG_PROXY_PROJECT_ID = Number(process.env.REACT_APP_BIG_PROXY_PROJECT_ID) as number;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
export const DOCUMENT_PRICE_ID = process.env.REACT_APP_STRIPE_DOCUMENT_PRICE_ID as string;
export const DOCUMENT_CURRENCY = process.env.REACT_APP_STRIPE_DOCUMENT_CURRENCY as string;
export const SUBSCRIPTION_TRIAL_DAYS = Number(
  process.env.REACT_APP_SUBSCRIPTION_TRIAL_DAYS
) as number;
export const SUBSCRIPTION_CURRENCY = process.env.REACT_APP_SUBSCRIPTION_CURRENCY as string;
export const REACT_APP_ADMIN_ROLE_ID = Number(process.env.REACT_APP_ADMIN_ROLE_ID || 0) as number