import { env } from "src/utils";

const MAX_DOCUMENT = 35;
const MIN_INDEX = 1;

export const getImageURL = (document_index: number, reversed = false) => {
  if (reversed) {
    return `${env.SERVICE_URL}/static/documents/d-${
      MAX_DOCUMENT - (document_index % MAX_DOCUMENT)
    }.png`;
  }

  return `${env.SERVICE_URL}/static/documents/d-${
    (document_index % MAX_DOCUMENT) + MIN_INDEX
  }.png`;
};
