import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  RefObject,
} from "react";
import cx from "classnames";

import styles from "./input.module.scss";

export interface IInputProps {
  id?: HTMLInputElement["id"];
  ref?: RefObject<HTMLInputElement>;
  name?: HTMLInputElement["name"];
  value?: HTMLInputElement["value"];
  placeholder?: HTMLInputElement["placeholder"];
  type?: HTMLInputElement["type"];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  className?: HTMLInputElement["className"];
  disabled?: HTMLInputElement["disabled"];
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      id,
      name,
      value = "",
      placeholder = "",
      type = "text",
      onChange,
      onFocus,
      onBlur,
      className,
      disabled,
    },
    ref
  ) => {
    return (
      <input
        id={id}
        data-testid="input"
        autoComplete="off"
        name={name}
        ref={ref}
        type={type}
        className={cx(styles.input, className)}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
    );
  }
);

Input.displayName = "Input";

export default React.memo(Input);
