import React, { FC, memo } from "react";
import { Button } from "../../forms";
import { always } from "../../utils";
import cx from "classnames";

import styles from "./Paginator.module.scss";

export type IPaginatorProps = {
  testId: string;
  value: string | number;
  className?: string;
  onClickLeftArrow?: () => void;
  onClickRightArrow?: () => void;
};

const Paginator: FC<IPaginatorProps> = ({
  value,
  className,
  onClickLeftArrow = always.EMPTY_FUNC,
  onClickRightArrow = always.EMPTY_FUNC,
  testId,
}) => {
  return (
    <div className={cx(styles.container, className)} data-testid={testId}>
      <Button.Icon
        view={Button.Icon.views.RIGHT_ARROW}
        onClick={onClickLeftArrow}
        className={styles.arrowLeft}
      />
      <div className={styles.value}>{value}</div>
      <Button.Icon
        view={Button.Icon.views.RIGHT_ARROW}
        onClick={onClickRightArrow}
      />
    </div>
  );
};

export default memo(Paginator);
