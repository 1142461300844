/* eslint-disable @typescript-eslint/no-explicit-any */
import { env, ls } from "src/utils";
import store, { settings } from "src/store";

const NOT_AUTHORIZED_STATUS = 401;

type IFetchParams<D> = {
  url: string;
  baseUrl?: string;
  body?: D;
  parseBody?: (param: D) => any;
  headers?: HeadersInit;
  method: "PUT" | "POST" | "GET" | "DELETE";
};

type IServerResponse<D> = { error: boolean; message: D; meta: unknown };

type IFetchResponse<R> = { response: Response; data: R };

const _fetch = async <R = any, D = any>({
  url,
  baseUrl = env.SERVICE_URL,
  body,
  parseBody = JSON.stringify,
  headers = {},
  method,
}: IFetchParams<D>): Promise<IFetchResponse<R>> => {
  const state = store.getState();
  const Language = settings.selectors.getLanguage(state);
  const token = ls.token.get();

  const response = await fetch(baseUrl + url, {
    body: body ? parseBody(body) : null,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "Accept-Language": Language,
      Language,
      ...headers,
    },
    method,
  });

  if (response.status === NOT_AUTHORIZED_STATUS) {
    ls.token.remove();
  }

  const data = await response.json();

  return { response, data };
};

const get = <R = any, D = any, RF = IServerResponse<R>>({
  url,
  headers,
}: Omit<IFetchParams<D>, "method">): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: "GET" });

const post = <R = any, D = any, RF = IServerResponse<R>>({
  url,
  headers,
  body,
}: Omit<IFetchParams<D>, "method">): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: "POST", body: body });

const put = <R = any, D = any, RF = IServerResponse<R>>({
  url,
  headers,
  body,
}: Omit<IFetchParams<D>, "method">): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: "PUT", body: body });

const remove = <R = any, D = any, RF = IServerResponse<R>>({
  url,
  headers,
  body,
}: Omit<IFetchParams<D>, "method">): Promise<IFetchResponse<RF>> =>
  _fetch({ url, headers, method: "DELETE", body: body });

const request_fetch = {
  get: (url: string, query?: any) => {
    const token = ls.token.get();
    return fetch(env.BIG_PROXY_SERVICE + url + "?" + new URLSearchParams(query), {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => res.json());
  },
  post: (url: string, body: any = "") => {
    const token = ls.token.get();
    return fetch(env.BIG_PROXY_SERVICE + url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  },
  put: (url: string, query?: any) => {
    const state = store.getState();
    const Language = settings.selectors.getLanguage(state);
    return fetch(env.SERVICE_URL + url + "?" + new URLSearchParams(query), {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        language: Language,
      },
    }).then((res) => res.json());
  },
};

export { _fetch, get, post, request_fetch, put, remove };
