/* eslint-disable @typescript-eslint/no-explicit-any */
import store from "./store";
import { createSelector } from "@reduxjs/toolkit";

const self = (state: Record<string, any>) => state?.[store.name];

export const getIsAuthorized = createSelector(self, ({ isAuthorized }) => isAuthorized);
export const getUser = createSelector(self, ({ user }) => user);

export const getUserSubscription = createSelector(self, ({ subscription }) => subscription);
