/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from "react";
import SearchFilter from "../SearchFilter";
import SortButton from "../SortButton";

import styles from "./styles.module.scss";
import { ITemplate } from "../Template";

interface IEnumServiceItems extends Record<string, any> {
  template: ITemplate[];
  gridTemplateColumns: string;
}

const Header: FC<IEnumServiceItems> = ({
  template,
  gridTemplateColumns,
  ...rest
}) => {
  return (
    <div
      style={{ gridTemplateColumns: gridTemplateColumns }}
      className={styles.header}
    >
      {template.map((_template: ITemplate) => {
        return (
          <div key={_template.valueKey} className={styles.header_item}>
            <SearchFilter template={_template} {...rest} />
            {_template.isSortEnable && (
              <SortButton valueKey={_template.valueKey} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default memo(Header);
