/* eslint-disable @typescript-eslint/no-explicit-any */
const _ls = (key: string) => {
  return {
    get: () => {
      return localStorage.getItem(key) as string;
    },
    set: (value: any) => {
      localStorage.setItem(key, value);
      window.dispatchEvent(
        new StorageEvent("storage", {
          newValue: value,
          key,
        })
      );
    },
    remove: () => {
      localStorage.removeItem(key);
      window.dispatchEvent(new StorageEvent("storage", { key }));
    },
    listen: <D = any>(callback: (value: D | null | string) => void): (() => void) => {
      const listener = (event: StorageEvent) => {
        if (event.key === key || event.key === null) {
          try {
            const value = event.newValue ? JSON.parse(event.newValue) : event.newValue;
            callback(value);
          } catch (e) {
            callback(event.newValue || null);
          }
        }
      };
      window.addEventListener("storage", listener);
      return () => window.removeEventListener("storage", listener);
    },
  };
};

export const token = _ls("token");
export const userId = _ls("userId");
export const countryId = _ls("cachedCountryId");
export const language = _ls("language");
export const user = _ls("user");
