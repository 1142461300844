import React, { useLayoutEffect, useMemo, useRef } from "react";
import { animated, useTransition } from "react-spring";
import styles from "./style.module.scss";
import cx from "classnames";

interface ITab {
  label: JSX.Element | string;
  component: JSX.Element | string;
  key?: string | number;
}

type IDirection = "left" | "center";

export interface ITabsStateless {
  items?: ITab[];
  currentTab?: number | string;
  onChange?: (tabIndex: number | string) => void;
  tabHeadDirection?: IDirection;
  className?: HTMLButtonElement["className"];
}

const TabsStateless: React.FC<ITabsStateless> = ({
  items = [],
  currentTab,
  onChange,
  tabHeadDirection = "center",
  className,
}) => {
  const currentTabIndex = useMemo(
    () => items.findIndex(({ key }) => String(currentTab) === String(key)),
    [currentTab]
  );
  const previousTab = useRef(currentTabIndex);

  const trans = useTransition(currentTabIndex, {
    key: (i: number) => items?.[i]?.key || i,
    from: () => ({
      transform: `translateX(${
        (currentTabIndex - previousTab.current) * 100
      }%)`,
      position: "static" as const,
    }),
    enter: () => ({
      transform: "translateX(0%)",
      position: "static" as const,
    }),
    leave: () => ({
      transform: `translateX(${
        (previousTab.current - currentTabIndex) * 100
      }%)`,
      zIndex: 1,
      position: "absolute" as const,
    }),
  });

  useLayoutEffect(() => {
    if (currentTabIndex !== previousTab.current) {
      previousTab.current = currentTabIndex;
    }
  }, [currentTabIndex]);

  return (
    <div className={cx(styles.tab, className)} data-testid="container">
      <div
        className={cx(styles.tab_list, {
          [styles.tab_list_left]: tabHeadDirection === "left",
        })}
        data-testid="head"
        data-type="head-block"
      >
        {items.map((item, tabIndex) => (
          <div
            className={cx(styles.tab__item, {
              [styles.tab__item_active]: tabIndex === currentTabIndex,
            })}
            key={item?.key || tabIndex}
            data-testid={item?.key || tabIndex}
            onClick={() => onChange && onChange(item.key as string)}
            data-type="head-item"
          >
            {item.label}
          </div>
        ))}
      </div>
      <div
        className={styles.tab__panel}
        data-testid="content"
        data-type="content-block"
      >
        {trans((styles, item) => (
          <animated.div
            style={{
              ...styles,
              width: "100%",
              height: "100%",
            }}
            data-testid="animated-content"
          >
            {items?.[item]?.component}
          </animated.div>
        ))}
      </div>
    </div>
  );
};

export default TabsStateless;
