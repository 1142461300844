/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useLayoutEffect, useRef } from "react";

export const useEvent = <H extends (...args: any) => any>(handler: H) => {
  const ref = useRef<H>(handler);

  useLayoutEffect(() => {
    ref.current = handler;
  }, [handler]);

  return useCallback(
    (...args: Parameters<H>) => ref.current.call(null, ...args),
    []
  );
};

export default useEvent;
