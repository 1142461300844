import React, { FC, useId } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { always, Button, useToggle } from "@package/components";
import { Input } from "@big-proxy/ui";
import { useI18n } from "src/hooks";
import { authorization } from "src/store";
import { useSelector } from "react-redux";
import { env } from "src/utils";
import cx from "classnames";
import * as yup from "yup";

import styles from "./Payment.module.scss";

const validationSchemaEmail = yup.string().email().required();
const validationSchemaCardholder = yup.string().min(3).required();
const withTry = (fn: () => unknown) => {
  try {
    fn();
    return { valid: true };
  } catch (error) {
    return { message: (error as any).message, valid: false };
  }
};

type PaymentProps = {
  onError: () => void;
  redirectUrl: string;
  status?: string;
  secret: string;
};

const Payment: FC<PaymentProps> = ({ redirectUrl, onError }) => {
  const i18n = useI18n();
  const [isSubmitting, setIsSubmitting] = useToggle(false);
  const authorizedUser = useSelector(authorization.selectors.getUser);
  const stripe = useStripe();
  const elements = useElements();
  const [isCompete, setIsCompete] = React.useState<boolean>(false);

  const idEmail = useId();
  const idCardholder = useId();
  const [email, setEmail] = React.useState(authorizedUser?.email ?? "");
  const usersCardholder = `${authorizedUser?.name ?? ""} ${authorizedUser?.surname ?? ""}`.trim();
  const [cardholder, setCardholder] = React.useState(usersCardholder);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return null;
    }
    setIsSubmitting.on();
    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: redirectUrl,
          payment_method_data: {
            billing_details: { email: email, name: cardholder },
          },
        },
      });
      if (result.error) {
        onError();
        return;
      }
    } catch (error) {
      onError();
      // eslint-disable-next-line no-console
      console.error(env.IS_DEVELOPMENT ? error : "Payment process error");
    } finally {
      setIsSubmitting.off();
    }
  };

  const isValidEmail = withTry(() => validationSchemaEmail.validateSync(email));
  const isValidCardholder = withTry(() => validationSchemaCardholder.validateSync(cardholder));

  return (
    <form onSubmit={handleSubmit} className={styles.payment_form}>
      {!authorizedUser?.email && (
        <div
          className={cx(styles.input_section, {
            [styles.error]: !isValidEmail.valid && email?.length,
          })}
        >
          <label htmlFor={idEmail} className={styles.label} title={isValidEmail.message}>
            {i18n.payment.email}
          </label>
          <Input
            id={idEmail}
            value={email}
            placeholder={"example@mail.com"}
            onChange={({ target: { value } }) => setEmail(value)}
            className={styles.field}
          />
        </div>
      )}

      {!usersCardholder && (
        <div
          className={cx(styles.input_section, {
            [styles.error]: !isValidCardholder.valid && cardholder?.length,
          })}
        >
          <label htmlFor={idCardholder} className={styles.label} title={isValidCardholder.message}>
            {i18n.payment.cardholder}
          </label>
          <Input
            id={idCardholder}
            value={cardholder}
            placeholder={i18n.payment.name + " " + i18n.payment.surname}
            onChange={({ target: { value } }) => setCardholder(value)}
            className={styles.field}
          />
        </div>
      )}

      <PaymentElement
        onChange={({ complete }) => {
          setIsCompete(complete);
        }}
      />

      <Button
        type="submit"
        onClick={always.EMPTY_FUNC}
        className={styles.pay_btn}
        disabled={
          isSubmitting ||
          !stripe ||
          !elements ||
          !isValidEmail.valid ||
          !isValidCardholder.valid ||
          !isCompete
        }
      >
        {i18n.payment.pay}
      </Button>
    </form>
  );
};

export default Payment;
