import { request_fetch } from "./api";
import { env } from "src/utils";

export const getPaymentMethod = () => 
request_fetch.get(`/payments/payment-methods`, { 
  projectId: env.BIG_PROXY_PROJECT_ID,
 });

export const getBillingInvoices = (query: { projectId: number; limit: number; page?: string }) =>
  request_fetch.get("/payments/invoices", query);

export const getSecretKey = (body = {}) => request_fetch.post("/payments/payment-methods", body);
