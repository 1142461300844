import React, { useEffect, useState, useCallback, ChangeEvent } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import { IBillingDetails, intialState } from "./types";
import { useI18n } from "src/hooks";
import SetupForm from "./setupForm";
import { subscription } from "@big-proxy/generics";
import * as services from "src/service";
import { env } from "src/utils";
import { ITranslationKey } from "../../i18n";
import { useSelector } from "react-redux";
import { settings } from "src/store";

import style from "./styles.module.scss";

const stripePromise = loadStripe(env.REACT_APP_STRIPE_BP_PUBLISH_KEY);

const { PaymentMethodsPopupChange } = subscription;

interface IPaymentMethod {
  isVisible: boolean;
  action: string;
  onClose: (isSuccess?: boolean) => void;
}

const PaymentMethod = ({ isVisible, onClose, action }: IPaymentMethod) => {
  const i18n = useI18n();
  const language: ITranslationKey = useSelector(settings.selectors.getLanguage);
  const [secret, setSecret] = useState("");
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>(intialState);

  useEffect(() => {
    if (isVisible) {
      const getSecret = async () => {
        const { error, message } = await services.billing.getSecretKey();
        if (!error) {
          setSecret(message?.secret);
        } else {
          console.log("error: not secret");
        }
      };
      getSecret().catch(console.error);
    }
  }, [isVisible]);

  useEffect(() => {
    const getPayment = async () => {
      const { error, message } = await services.billing.getPaymentMethod();
      if (!error) {
        setBillingDetails((prevState) => ({ ...prevState, ...message }));
      }
    };
    getPayment().catch(console.error);
  }, []);

  const onChangeName = useCallback((e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setBillingDetails((prev) => ({
      ...prev,
      billing_details: { ...prev.billing_details, [name]: value },
    }));
  }, []);

  const onChangeAddress = useCallback((e: ChangeEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setBillingDetails((prev) => ({
      ...prev,
      billing_details: {
        ...prev.billing_details,
        address: { ...prev.billing_details.address, [name]: value },
      },
    }));
  }, []);

  const options = {
    clientSecret: secret,
    locale: language as StripeElementLocale,
  };

  return (
    <PaymentMethodsPopupChange
      className={style.modalPayment}
      isVisible={isVisible}
      onClose={onClose}
      translations={{
        bigId: i18n.profile.billing.paymentMethod.bigId,
        questionMarkTitle: " ",
        title:
          action === "create"
            ? i18n.profile.billing.paymentMethod.createPaymentMethod
            : i18n.profile.billing.paymentMethod.changePaymentMethod,
      }}
    >
      {secret && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm
            secret={secret}
            action={action}
            onClose={onClose}
            billingDetails={billingDetails}
            onChangeAddress={onChangeAddress}
            onChangeName={onChangeName}
          />
        </Elements>
      )}
    </PaymentMethodsPopupChange>
  );
};

export default PaymentMethod;
