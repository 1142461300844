import { useResource } from "./useResource";
import { country_language } from "src/service";

export type ICountryResource = { code: string; name: string };

export const useCountriesResource = () => {
  return useResource<ICountryResource>("countries-resource", async () => {
    const { data } = await country_language.getCountries();
    return { data: data.message };
  });
};
