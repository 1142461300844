import { get } from "./api";
import { env } from "src/utils";

export const newCreateDocumentUrl = (documentId: string) => {
  return env.CONSTRUCTOR_URL + "/document/new/" + documentId;
};

export const openDocumentUrl = (documentId: string) =>
  env.CONSTRUCTOR_URL + "/document/open/" + documentId;

export const createTemplateUrl = env.CONSTRUCTOR_URL + "/";

export const openTemplateUrl = (documentId: string) =>
  env.CONSTRUCTOR_URL + "/constructor/" + documentId;

export const getSubscriptionDocumentsCount = () =>
  get<{ documentsCount: string }>({ url: "/documents/chkSubscription" });

export const getHomeTemplates = (countryId: number, languageId: number) =>
  get({ url: "/templates/" + countryId + "/" + languageId });
