import React, { useState, ChangeEvent, MouseEvent, useCallback } from "react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";
import { useI18n } from "src/hooks";
import countries from "./countries";
import { IBillingDetails } from "./types";
import { Button, Input, Select, useToggle, useWindowBridge, WindowBridge } from "@big-proxy/ui";
import { SetupIntentResult } from "@stripe/stripe-js";

interface ISetupForm {
  secret: string;
  action: string;
  onClose: (isSuccess?: boolean) => void;
  billingDetails: IBillingDetails;
  onChangeName: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeAddress: (e: ChangeEvent<HTMLInputElement>) => void;
}

const sleep = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

const SetupForm = ({
  secret,
  onClose,
  action,
  billingDetails,
  onChangeName,
  onChangeAddress,
}: ISetupForm) => {
  const windowBridge = useWindowBridge();
  const [isLoading, setIsLoading] = useToggle(false);
  const [errors, setErrors] = useState<string | null>(null);
  const i18n = useI18n();

  const stripe = useStripe();
  const elements = useElements();
  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#77787e",
        fontSize: "16px",
        "::placeholder": {
          color: "#77787e",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleSetupIntent = useCallback(
    async (result: SetupIntentResult) => {
      if (result?.error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setErrors(result?.error?.message);
        setIsLoading.off();
        return { success: false };
      }

      if (result?.setupIntent?.status === "requires_payment_method") {
        setErrors(result?.setupIntent?.last_setup_error?.message ?? "requires_payment_method");
        setIsLoading.off();
        return { success: false };
      }

      if (result?.setupIntent?.status === "succeeded") {
        await sleep(5_000);

        setIsLoading.off();
        onClose(true);
        return { success: true };
      }

      if (result?.setupIntent?.status === "requires_action") {
        const windowRef = window.open(
          result?.setupIntent?.next_action?.redirect_to_url?.url as string,
          "_blank"
        );
        if (windowRef) {
          return new Promise((resolve) => {
            const _windowBridge = windowBridge.initialize({
              target: windowRef,
              closeTargetListenerEnabled: true,
              onReady: (bridge) => {
                bridge.on("SETUP_COMPETE", async (data: any) => {
                  bridge.emit("CLOSE_WINDOW", {});
                  _windowBridge.release();

                  const clientSecret = data?.setup_intent_client_secret;
                  return (stripe as NonNullable<typeof stripe>)
                    .retrieveSetupIntent(clientSecret)
                    .then(handleSetupIntent)
                    .then(resolve);
                });
              },
            });

            _windowBridge.on(WindowBridge.EVENTS.CLOSED, () => {
              _windowBridge.release();

              setErrors("Window was closed");
              setIsLoading.off();
              resolve({ success: false });
            });
          });
        }
        return { success: false };
      }

      setIsLoading.off();
      return { success: false };
    },
    [onClose, setIsLoading, stripe, windowBridge]
  );

  const onSubmitHandler = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading.on();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    await stripe
      ?.confirmCardSetup(
        secret,
        {
          return_url: `${window.location.origin}/callback/stripe-card-setup/`,
          payment_method: {
            card: elements.getElement(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              CardNumberElement,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              CardCvcElement,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              CardExpiryElement
            ) as any,
            billing_details: {
              name: billingDetails?.billing_details.name,
              address: {
                line1: billingDetails?.billing_details?.address?.line1,
                line2: billingDetails?.billing_details?.address?.line2,
                postal_code: billingDetails?.billing_details?.address?.postal_code,
                city: billingDetails?.billing_details?.address?.city,
                country: billingDetails?.billing_details?.address?.country,
              },
            },
          },
        },
        { handleActions: false }
      )
      .then(handleSetupIntent)
      .catch(() => {
        setIsLoading.off();
        return { success: false };
      });
  };

  return (
    <div>
      <form className={styles.formPaymentMethod}>
        <Input
          placeholder={i18n.profile.billing.paymentMethod.name}
          value={billingDetails?.billing_details?.name}
          name="name"
          onChange={onChangeName}
        />
        <fieldset className={styles.formGroup}>
          <div className={styles.formRow}>
            <div>{i18n.profile.billing.paymentMethod.cardNumber}</div>
            <CardNumberElement
              className={styles.CardElement}
              options={
                {
                  iconStyle: "solid",
                  showIcon: true,
                  ...cardStyle,
                } as any
              }
            />
          </div>
        </fieldset>
        <div className={styles.expirationContainer}>
          <fieldset className={styles.formGroup}>
            <div className={styles.formRowNext}>
              <div>{i18n.profile.billing.paymentMethod.expiration}</div>
              <CardExpiryElement className={styles.cardExpiryElement} />
            </div>
          </fieldset>
          <fieldset className={styles.formGroup}>
            <div className={styles.formRowNext}>
              <div>{i18n.profile.billing.paymentMethod.cvc}</div>
              <CardCvcElement className={styles.cardCvcElement} />
            </div>
          </fieldset>
        </div>
        <fieldset className={styles.formGroup}>
          <div className={styles.formRowNext}>
            <div>{i18n.profile.billing.paymentMethod.country}</div>
            <Select
              value={billingDetails?.billing_details?.address?.country}
              name="country"
              onChange={onChangeAddress}
              options={countries}
              idSelector={({ code }) => code}
              valueSelector={({ country }) => country}
            />
          </div>
        </fieldset>

        <div className={styles.titleBilling}>
          {i18n.profile.profile.billingAddress.billingAddressTitle}
        </div>
        <Input
          placeholder={i18n.profile.billing.paymentMethod.line1}
          value={billingDetails?.billing_details?.address?.line1}
          name="line1"
          onChange={onChangeAddress}
        />
        <div className={styles.postalCodeContainer}>
          <Input
            placeholder={i18n.profile.billing.paymentMethod.postalCode}
            value={billingDetails?.billing_details?.address?.postal_code}
            name="postal_code"
            onChange={onChangeAddress}
          />

          <Input
            placeholder={i18n.profile.billing.paymentMethod.city}
            value={billingDetails?.billing_details?.address?.city}
            name="city"
            onChange={onChangeAddress}
          />
        </div>
        <div className={styles.errorsText}>{errors}</div>
      </form>

      <div className={styles.submitBtn}>
        <Button onClick={onSubmitHandler} disabled={isLoading}>
          {action === "change"
            ? i18n.profile.billing.paymentMethod.update
            : i18n.profile.billing.paymentMethod.next}
        </Button>
      </div>
    </div>
  );
};

export default SetupForm;
