import React, { FC } from "react";
import { Link, generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@package/components";
import { authorization } from "src/store";
import profile from "src/modules/Profile";
import { useI18n } from "src/hooks";

import styles from "./styles.module.scss";

const generatedPath = "/" + generatePath(profile.path, { "*": "" });

const AuthorizedCircle: FC<{ name: string; surname: string }> = ({ surname, name }) => {
  return (
    <Link to={generatedPath} className={styles.authorized}>
      {name?.[0]?.toUpperCase() || ""}.{surname?.[0]?.toUpperCase() || ""}
    </Link>
  );
};

const Auth: FC = () => {
  const i18n = useI18n();
  const isAuthorized = useSelector(authorization.selectors.getIsAuthorized);
  const authorizedUser = useSelector(authorization.selectors.getUser);

  return (
    <>
      {isAuthorized ? (
        <AuthorizedCircle name={authorizedUser.name} surname={authorizedUser.surname} />
      ) : (
        <Button
          color={Button.colors.White}
          border={Button.borders.Coral}
          fill={Button.fills.Coral}
          onClick={() => window.open("/", "_self")}
          className={styles.sign_in}
        >
          {i18n.common.sign_in}
        </Button>
      )}
    </>
  );
};

export default Auth;
