import React, { FC, useCallback } from "react";
import { useI18n } from "src/hooks";
import { Button } from "@package/components";
import cx from "classnames";
import { IDocument } from "../types";

import styles from "./index.module.scss";

const DeleteDocumentModal: FC<{
  id: IDocument["id"];
  onClose: () => void;
  onDelete: (id: IDocument["id"]) => void;
}> = ({ onClose, id, onDelete }) => {
  const i18n = useI18n();

  const onDeleteDocument = useCallback(() => {
    onDelete(id);
  }, [id]);

  return (
    <div className={styles.frame}>
      <div className={styles.container}>
        <p className={styles.text}>{i18n.profile.delete_document_message}</p>

        <div className={cx(styles.row, styles.buttons_bar)}>
          <Button
            border={Button.borders.HavelockBlue}
            color={Button.colors.HavelockBlue}
            className={styles.button}
            onClick={onClose}
          >
            {i18n.common.cancel}
          </Button>
          <Button
            border={Button.borders.HavelockBlue}
            color={Button.colors.HavelockBlue}
            className={styles.button}
            onClick={onDeleteDocument}
          >
            {i18n.common.delete}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteDocumentModal;
