import { useResource } from "./useResource";
import { country_language } from "src/service";

export type ICountryLanguageResource = {
  codeCountry: string;
  countryId: string;
  countryName: Record<string, string>;
  children: string[];
  childrenEntities: {
    id: number;
    short: string;
    long: Record<string, string>;
  }[];
};

export const COUNTRY_LANGUAGE_RESOURCE_KEY = "country-language-resource";
export const useCountryLanguageResource = () => {
  return useResource<ICountryLanguageResource>(
    COUNTRY_LANGUAGE_RESOURCE_KEY,
    async () => {
      const { data } = await country_language.getCountryLanguages();
      return { data: data.message };
    },
    {
      unmountDelay: 3000,
    }
  );
};
