import React, { FC, MouseEventHandler, RefObject } from "react";
import styles from "./Modal.module.scss";
import Icon from "../Icon";
import { createPortal } from "react-dom";
import { useClickOutsideRef } from "../../hooks/useClickOutside";
import { useKeyPress } from "../../hooks/useKeyPress";
import * as utils from "../../utils";
import Button from "../Button";

export interface IModal {
  isVisible?: boolean;
  children?: React.ReactNode;
  containerRef?: RefObject<HTMLDivElement> | null;
}

export interface IHeadered extends IModal {
  onClose?: () => void;
  title?: React.ReactNode;
  titleComponent?: React.FC;
  disableClickOutside?: boolean;
}

const Modal: FC<IModal> & { Headered: typeof Headered } = ({
  isVisible,
  containerRef,
  children,
}) => {
  return isVisible ? (
    createPortal(
      <div className={styles.frame} data-testid="backdrop">
        <div className={styles.container} ref={containerRef} data-testid="modal">
          <div className={styles.content}>{children}</div>
        </div>
      </div>,
      document.body
    )
  ) : (
    <></>
  );
};

const TitleComponent: FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={styles.title}>{children}</div>
);

const Headered: React.FC<IHeadered> = ({
  onClose,
  titleComponent: Title = TitleComponent,
  title,
  children,
  disableClickOutside = false,
  ...props
}) => {
  const closeHandler = () => {
    onClose && onClose();
  };

  useKeyPress("Escape", closeHandler);
  const closeRef = useClickOutsideRef<HTMLDivElement, typeof closeHandler>(
    disableClickOutside ? utils.always.EMPTY_FUNC : closeHandler
  );

  return (
    <Modal {...props} containerRef={closeRef}>
      <div className={styles.header} data-testid="modal-header">
        <Title>{title}</Title>
        <Button.Icon
          view={Icon.views.CLOSE}
          className={styles.close}
          onClick={onClose as unknown as MouseEventHandler<HTMLButtonElement>}
        />
      </div>
      <div className={styles.content} data-testid="modal-content">
        {children}
      </div>
    </Modal>
  );
};

Modal.Headered = Headered;

Modal.displayName = "Modal";

export default Modal;
