export const MEX = 'Mex'
export const ADMIN_LIST_SETTINGS = 'admin_list_settings'
export const ARROW_BACK = 'arrow-back'
export const ARROW_GR = 'arrow-gr'
export const AUSTRALIA = 'australia'
export const AUSTRIA = 'austria'
export const BASKET = 'basket'
export const BELGIUM = 'belgium'
export const BILLING = 'billing'
export const BRAZIL = 'brazil'
export const CANADA = 'canada'
export const CARD_ERROR = 'card-error'
export const CIRCLED_PLUS = 'circled-plus'
export const CLIP = 'clip'
export const CLOSE = 'close'
export const CLOSE1 = 'close1'
export const COPY = 'copy'
export const CROSS = 'cross'
export const DELETE = 'delete'
export const DOCUMENTS = 'documents'
export const DOWNLOAD = 'download'
export const EARTH = 'earth'
export const EDIT = 'edit'
export const F_FR = 'f-fr'
export const F_GER = 'f-ger'
export const F_IT = 'f-it'
export const F_SW = 'f-sw'
export const F_USA = 'f-usa'
export const FACEBOOK = 'facebook'
export const FULL_TRIANGLE_ARROW = 'full-triangle-arrow'
export const GLOBE = 'globe'
export const GOOGLE = 'google'
export const IN = 'in'
export const INFO = 'info'
export const IRELAND = 'ireland'
export const LINK = 'link'
export const LINKED_IN = 'linked_in'
export const LOG_OUT = 'log-out'
export const LOGO = 'logo'
export const LOGOUT = 'logout'
export const LUXEMBOURG = 'luxembourg'
export const MOBILE_LOGO = 'mobile_logo'
export const PAGE_404 = 'page_404'
export const POLAND = 'poland'
export const PORTUGAL = 'portugal'
export const PROFIL = 'profil'
export const PROFILE = 'profile'
export const RIGHT_ARROW_BTN = 'right-arrow-btn'
export const RIGHT_ARROW = 'right-arrow'
export const SEARCH = 'search'
export const SETTINGS = 'settings'
export const SPAIN = 'spain'
export const STAR = 'star'
export const STYLED_LOGO = 'styled-logo'
export const SUBSCRIPTIONS = 'subscriptions'
export const THREE_DOTS = 'three-dots'
export const TRASH = 'trash'
export const TRIANGLE_BOTTOM_ARROW = 'triangle-bottom-arrow'
export const TRIANGLE_TOP_ARROW = 'triangle-top-arrow'
export const TWITTER = 'twitter'
export const UNITED_KINGDOM = 'united-kingdom'
