/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { useDynamicReducer } from "../useDynamicReducer";
import { ChangeEventHandler, useCallback, useMemo } from "react";
import { batch, useSelector, useStore } from "react-redux";
import { useDebouncedCallback } from "@package/components";
import { env } from "src/utils";

export const DEBOUNCE_RELOAD_TIME = env.IS_TEST ? 10 : 1000;

const getResourceStore = <T>(name: string) =>
  createSlice({
    name,
    initialState: {
      isLoading: false,
      isLoaded: false,
      isLoadedAll: false,
      filter: {},
      sort: {},
      entities: [] as T[],
      error: "",
    },
    reducers: {
      setIsLoad(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setIsLoaded(state, action: PayloadAction<boolean>) {
        state.isLoaded = action.payload;
      },
      setIsLoadedAll(state, action: PayloadAction<boolean>) {
        state.isLoadedAll = action.payload;
      },
      setError(state, action: PayloadAction<string>) {
        state.error = action.payload;
      },

      mergeEntities(state, action: PayloadAction<T[]>) {
        state.entities = [
          ...state.entities,
          ...(action.payload as unknown as Draft<T>[]),
        ];
      },
      setEntities(state, action: PayloadAction<T[]>) {
        state.entities = action.payload as unknown as Draft<T>[];
      },

      setFilter(state, action: PayloadAction<Record<string, any>>) {
        state.filter = action.payload;
      },
      mergeFilter(state, action: PayloadAction<Record<string, any>>) {
        state.filter = { ...state.filter, ...action.payload };
      },

      setSort(state, action: PayloadAction<Record<string, any>>) {
        state.sort = action.payload;
      },
      mergeSort(state, action: PayloadAction<Record<string, any>>) {
        state.sort = { ...state.sort, ...action.payload };
      },
    },
  });

export const selectors = {
  getFilter: (state: any, name: string) => state?.[name]?.filter,
  getSort: (state: any, name: string) => state?.[name]?.sort,
  getEntries: (state: any, name: string) => state?.[name]?.entities,
  getIsLoading: (state: any, name: string) => state?.[name]?.isLoading,
  getIsLoaded: (state: any, name: string) => state?.[name]?.isLoaded,
  getIsLoadedAll: (state: any, name: string) => state?.[name]?.isLoadedAll,
};

type ILoader<T> = (props: {
  filter: Record<string, any>;
  sort: Record<string, any>;
  entries: T[];
}) => Promise<{ data: T[]; isLoadedAll?: boolean }>;

type IOptions = {
  unmountDelay?: number;
};

export const useResourceStore = <T, S extends Slice>(
  store: S,
  loader: ILoader<T>,
  options: IOptions = { unmountDelay: undefined }
) => {
  useDynamicReducer(store.name, store.reducer, options?.unmountDelay);

  const { dispatch, getState } = useStore();

  const entities: T[] = useSelector(
    (state) =>
      selectors.getEntries(state, store.name) ||
      selectors.getEntries({ state: store.getInitialState() }, "state")
  );
  const sort: Record<string, any> = useSelector(
    (state) =>
      selectors.getSort(state, store.name) ||
      selectors.getSort({ state: store.getInitialState() }, "state")
  );
  const filter: Record<string, any> = useSelector(
    (state) =>
      selectors.getFilter(state, store.name) ||
      selectors.getFilter({ state: store.getInitialState() }, "state")
  );
  const isLoading: boolean = useSelector(
    (state) =>
      selectors.getIsLoading(state, store.name) ||
      selectors.getIsLoading({ state: store.getInitialState() }, "state")
  );
  const isLoaded: boolean = useSelector(
    (state) =>
      selectors.getIsLoaded(state, store.name) ||
      selectors.getIsLoaded({ state: store.getInitialState() }, "state")
  );

  const onLoad = useCallback(async () => {
    const state = getState();
    const isLoadedAll = selectors.getIsLoadedAll(state, store.name);
    const isLoading = selectors.getIsLoading(state, store.name);

    if (isLoadedAll || isLoading) {
      return;
    }

    const filter = selectors.getFilter(state, store.name);
    const sort = selectors.getSort(state, store.name);
    const entries = selectors.getEntries(state, store.name);

    batch(() => {
      dispatch(store.actions.setIsLoad(true));
      dispatch(store.actions.setError(""));
    });

    await loader({ filter, sort, entries })
      .then(({ data, isLoadedAll = false }) => {
        batch(() => {
          dispatch(store.actions.mergeEntities(data));
          dispatch(store.actions.setIsLoaded(true));
          dispatch(store.actions.setIsLoad(false));
          dispatch(store.actions.setIsLoadedAll(isLoadedAll));
        });
      })
      .catch((err) => {
        batch(() => {
          dispatch(store.actions.setError(err));
          dispatch(store.actions.setIsLoad(false));
        });
      });
  }, [loader]);

  const onReload = useCallback(async () => {
    const state = getState();
    const filter = selectors.getFilter(state, store.name);
    const sort = selectors.getSort(state, store.name);

    batch(() => {
      dispatch(store.actions.setIsLoad(true));
      dispatch(store.actions.setEntities([]));
      dispatch(store.actions.setIsLoaded(false));
      dispatch(store.actions.setError(""));
    });

    const entries = selectors.getEntries(getState(), store.name);

    await loader({ filter, sort, entries })
      .then(({ data, isLoadedAll = false }) => {
        batch(() => {
          dispatch(store.actions.setFilter(filter));
          dispatch(store.actions.setSort(sort));
          dispatch(store.actions.setEntities(data));
          dispatch(store.actions.setIsLoad(false));
          dispatch(store.actions.setIsLoaded(true));
          dispatch(store.actions.setIsLoadedAll(isLoadedAll));
        });
        return { data, isLoadedAll };
      })
      .catch((err) => {
        batch(() => {
          dispatch(store.actions.setError(err));
          dispatch(store.actions.setIsLoad(false));
        });
      });
  }, [loader]);

  const onReloadLazy = useCallback(async () => {
    const state = getState();
    const isLoaded = selectors.getIsLoaded(state, store.name);
    const isLoading = selectors.getIsLoading(state, store.name);

    if (!isLoaded && !isLoading) {
      await onReload();
    }
  }, [onReload]);

  const onReloadDebounced = useDebouncedCallback(
    onReload,
    DEBOUNCE_RELOAD_TIME
  );

  const onLoadDebounced = useDebouncedCallback(onLoad, DEBOUNCE_RELOAD_TIME);

  const onFilter = useCallback(
    (name: string, value: any) => {
      dispatch(store.actions.mergeFilter({ [name]: value }));
    },
    [dispatch, store]
  );

  const onFilterEventHandler: ChangeEventHandler = useCallback(
    ({ target }) => {
      const { name, value } = target as HTMLInputElement;
      onFilter(name, value);
      onReloadDebounced();
    },
    [onFilter]
  );

  const onSort = useCallback(
    (name: string, value: any) => {
      dispatch(store.actions.mergeSort({ [name]: value }));
    },
    [dispatch, store]
  );

  const onSortEventHandler: ChangeEventHandler = useCallback(
    ({ target }) => {
      const { name, value } = target as HTMLInputElement;
      onSort(name, value);
      onReloadDebounced();
    },
    [onSort]
  );

  const onReset = useCallback(() => {
    dispatch(store.actions.setFilter({}));
    dispatch(store.actions.setSort({}));
    dispatch(store.actions.setEntities([]));
  }, []);

  const onResetAndReload = useCallback(async () => {
    onReset();
    return onReload();
  }, []);

  return {
    store,
    onLoad,
    onLoadDebounced,
    onReload,
    onReloadLazy,
    onReloadDebounced,
    onReset,
    onResetAndReload,
    onFilter,
    onFilterEventHandler,
    onSort,
    onSortEventHandler,
    isLoading,
    isLoaded,
    entities,
    sort,
    filter,
  } as const;
};

useResourceStore.getResourceStore = getResourceStore;

export const useResource = <T>(
  name: string,
  loader: ILoader<T>,
  options?: IOptions
) => {
  const store = useMemo(() => getResourceStore<T>(name), [name]);
  return useResourceStore(store, loader, options);
};
