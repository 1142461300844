/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEventHandler, FC, memo } from "react";
import Option, { IOptionProps } from "../Option";
import { useArrowsKeyPress } from "../useArrowsKeyPress";
import { IOptionsMultiProps } from "./Options.multi";
import { useClickOutside } from "../../../hooks";

import styles from "./Options.module.scss";

export interface IOptionsProps {
  value: string | number;
  options: any[];
  bounds: DOMRect;
  inputRef: React.RefObject<HTMLDivElement>;
  optionsRef: React.RefObject<HTMLDivElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  valueSelector: (e: any) => string;
  idSelector: (e: any) => unknown;
  onClose: () => void;
  renderOption?: (props: IOptionProps) => React.ReactElement;
}

const defaultOptionComponent: IOptionsMultiProps["renderOption"] = ({
  id,
  isSelected,
  isHighlighted,
  option,
  onChange,
  valueSelector,
}) => (
  <Option
    id={id}
    key={id as string}
    option={option}
    isSelected={isSelected}
    isHighlighted={isHighlighted}
    valueSelector={valueSelector}
    onChange={onChange}
  />
);

const Options: FC<IOptionsProps> = ({
  value,
  options,
  bounds,
  onChange,
  valueSelector,
  inputRef,
  optionsRef,
  idSelector,
  onClose,
  renderOption = defaultOptionComponent,
}) => {
  const [position] = useArrowsKeyPress(
    optionsRef,
    options,
    idSelector,
    onChange
  );

  useClickOutside(optionsRef, onClose, [inputRef]);

  return (
    <div
      className={styles.options}
      style={{
        top: bounds.top + bounds.height,
        width: bounds.width,
        left: bounds.left,
      }}
      ref={optionsRef}
      data-testid="options-container"
    >
      {options.map((option: any, index) => {
        const id = idSelector(option);
        return renderOption?.({
          id,
          isSelected: id === value,
          isHighlighted: position === index,
          option,
          onChange,
          valueSelector,
        });
      })}
    </div>
  );
};

export default memo(Options);
