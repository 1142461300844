import React, { MutableRefObject } from "react";

const useDebouncedCallback = <
  F extends (...args: any[]) => void = (...args: any[]) => void
>(
  callback: F,
  delay: number,
  dependencies?: any[]
) => {
  const timeout = React.useRef<number>(null) as MutableRefObject<number>;

  // Avoid error about spreading non-iterable (undefined)
  const comboDeps = dependencies
    ? [callback, delay, ...dependencies]
    : [callback, delay];

  return React.useCallback((...args: Parameters<F>) => {
    if (timeout.current != null) {
      clearTimeout(timeout.current);
    }

    const timerId = setTimeout(() => {
      callback(...args);
    }, delay);

    timeout.current = timerId as unknown as number;
  }, comboDeps);
};

export default useDebouncedCallback;
