/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  ChangeEventHandler,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import Input from "../Input";
import Button from "../../forms/Button";
import { useDimensions, useToggle } from "../../hooks";
import { Options, OptionsMulti } from "./Options";
import * as utils from "./utils";
import { createPortal } from "react-dom";

import styles from "./select.module.scss";

export interface ISelectProps {
  name?: HTMLInputElement["name"];
  value: HTMLInputElement["value"];
  disabled?: HTMLInputElement["disabled"];
  placeholder?: HTMLInputElement["placeholder"];
  className?: HTMLInputElement["className"];
  options: any[];
  boundaries?: HTMLElement | null;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onInputChange?: ChangeEventHandler<HTMLInputElement>;
  idSelector?: (entity: any) => unknown;
  valueSelector?: (entity: any) => string;
  renderOption?: React.ComponentProps<typeof OptionsMulti>["renderOption"];
  hiddenSelector?: (entity: any) => boolean;
  filterSelector?: (
    entity: any,
    filterQuery: string,
    valueSelector: ISelectProps["valueSelector"]
  ) => boolean;
}

const Select = React.forwardRef<HTMLInputElement, ISelectProps>(
  (
    {
      name,
      value,
      disabled,
      options,
      placeholder,
      onChange,
      onInputChange,
      className,
      idSelector = utils.defaultIdSelector,
      valueSelector = utils.defaultValueSelector,
      filterSelector = utils.defaultFilterSelector,
      hiddenSelector,
      boundaries,
      renderOption,
    },
    ref
  ) => {
    const _inputRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLInputElement>(null);
    const inputRef = (ref || _inputRef) as RefObject<HTMLInputElement>;

    const [isVisible, onToggleVisible] = useToggle();
    const [isFocused, setIsFocused] = useToggle(false);
    const [filterQuery, setFilterQuery] = useState("");

    const [refDimensions, bounds] = useDimensions(onToggleVisible.off);

    const filteredOptions = useMemo(() => {
      let _options = [...options];
      if (filterQuery) {
        const filterFn = (option: any) =>
          filterSelector(option, filterQuery, valueSelector);
        _options = _options.filter(filterFn);
      }
      if (hiddenSelector) {
        _options = _options.filter(hiddenSelector);
      }
      return _options;
    }, [filterQuery, options, hiddenSelector, filterSelector]);

    const onChangeHandler = useCallback(
      (_value: any) => {
        onChange({
          target: { value: idSelector(_value), name },
        } as ChangeEvent<HTMLInputElement>);
        inputRef?.current?.blur();
        onToggleVisible.off();
        setIsFocused.off();
        setFilterQuery("");
      },
      [onChange, name, idSelector, inputRef]
    );

    const onChangeInputHandler: ChangeEventHandler<HTMLInputElement> =
      useCallback(
        ({ target: { value, name } }) => {
          if (onInputChange) {
            onInputChange({
              target: { value, name },
            } as ChangeEvent<HTMLInputElement>);
          }
          setFilterQuery(value);
        },
        [onInputChange]
      );

    const onFocusHandler = useCallback(() => {
      onToggleVisible.on();
      setIsFocused.on();
      inputRef?.current?.focus();
    }, []);

    const onBlurHandler = useCallback((e: React.FocusEvent) => {
      if (optionsRef?.current?.contains(e.relatedTarget)) {
        return;
      }
      setIsFocused.off();
      setFilterQuery("");
    }, []);

    const label = useMemo(() => {
      const compare = (entity: any) =>
        String(idSelector(entity)) === String(value);
      const _option = options.find(compare);
      return _option ? valueSelector(_option) : "";
    }, [options, value, idSelector, valueSelector]);

    return (
      <>
        <div
          className={cx(styles.control, { [styles.disabled]: disabled })}
          ref={refDimensions}
        >
          <Input
            ref={inputRef}
            name={name}
            value={isFocused ? filterQuery : label}
            onChange={onChangeInputHandler}
            disabled={disabled}
            placeholder={isFocused ? label : placeholder}
            className={cx(styles.input, className)}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
          <Button.Icon
            view={Button.Icon.views.FULL_TRIANGLE_ARROW}
            onClick={onFocusHandler}
            disabled={disabled}
            className={cx(styles.triangle_icon, {
              [styles.open]: isVisible && !disabled,
            })}
          />
        </div>
        {isVisible &&
          !disabled &&
          createPortal(
            <Options
              inputRef={inputRef}
              optionsRef={optionsRef}
              renderOption={renderOption}
              bounds={bounds}
              onClose={onToggleVisible.off}
              value={value}
              options={filteredOptions}
              onChange={onChangeHandler}
              valueSelector={valueSelector}
              idSelector={idSelector}
            />,
            boundaries || document.body
          )}
      </>
    );
  }
);

Select.displayName = "Select";

export default Select;
