import { get, put, post, remove } from "./api";

export const getDropdowns = (countryId: string, query: string) =>
  get({ url: "/dropdowns/all/" + countryId + "?" + query });

export const updateDropdown = (id: string, body: Record<string, any>) =>
  put({ url: "/dropdowns/update/" + id, body });

export const createDropdown = (countryId: string, body: Record<string, any>) =>
  post({ url: "/dropdowns/add/" + countryId, body });

export const deleteDropdown = (id: string) =>
  remove({ url: "/dropdowns/remove/" + id });
