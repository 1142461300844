import React, { FC } from "react";
import { always } from "../../utils";
import Icon, { IIcon } from "../Icon";
import { IButton } from "./Button";
import classNames from "classnames";

import styles from "./button.icon.module.scss";

export interface IButtonIcon
  extends Omit<IButton, "fill" | "color" | "border" | "type" | "children"> {
  /** One of "Button.Icon.views" or "Icon.views" property */
  view: IIcon["view"];
}

const ButtonIcon: FC<IButtonIcon> & { views: typeof Icon.views } = ({
  onClick = always.EMPTY_FUNC,
  view,
  value,
  name,
  disabled,
  className,
}) => {
  return (
    <button
      className={classNames(styles.container, className)}
      type="button"
      data-testid="button.icon"
      onClick={onClick}
      value={value}
      name={name}
      disabled={disabled}
    >
      <Icon
        view={view}
        className={classNames({ [styles.disabled]: disabled })}
      />
    </button>
  );
};

ButtonIcon.displayName = "Button.Icon";
ButtonIcon.views = Icon.views;

export default ButtonIcon;
