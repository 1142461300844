import React, { RefObject, useCallback, useEffect, useMemo } from "react";
import { getPosition, placements } from "../calculation";
import cx from "classnames";
import { useToggle } from "../../../hooks";

import styles from "./style.module.scss";

export interface IPopoverProps {
  ref: RefObject<HTMLDivElement>;
  triangleSize?: number;
  placement: placements;
  bounds: DOMRect;
  contentBounds: DOMRect;
  children?: React.ReactNode;
  onPopupClose: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const SMOOTH_TIMER = 100;

const Prompt = React.forwardRef<HTMLDivElement, IPopoverProps>(
  (
    {
      onPopupClose,
      triangleSize = 0,
      bounds,
      contentBounds,
      placement,
      children,
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useToggle(false);

    // side effect to make visibility more smooth
    useEffect(() => {
      const timerId = setTimeout(setIsVisible.on, SMOOTH_TIMER);
      return () => {
        setIsVisible.off();
        clearTimeout(timerId);
      };
    }, [setIsVisible]);

    const contentStyles = useMemo((): React.CSSProperties => {
      const { top, left, padding } = getPosition(
        placement,
        contentBounds,
        bounds,
        triangleSize
      );
      return {
        top: `${top}px`,
        left: `${left}px`,
        padding: `${padding}`,
      };
    }, [bounds, contentBounds]);

    const triangleStyles = useMemo((): React.CSSProperties => {
      const {
        borderLeft,
        borderRight,
        borderTop,
        borderBottom,
        triangleTop,
        triangleLeft,
      } = getPosition(placement, contentBounds, bounds, triangleSize);
      return {
        borderLeft: `${borderLeft}`,
        borderRight: `${borderRight}`,
        borderTop: `${borderTop}`,
        borderBottom: `${borderBottom}`,
        top: `${triangleTop}px`,
        left: `${triangleLeft}px`,
      };
    }, [bounds, contentBounds]);

    const stopBubbling = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    }, []);

    return (
      <div
        className={cx(styles.prompt, { [styles.hidden]: !isVisible })}
        ref={ref}
        onMouseLeave={onPopupClose}
        style={contentStyles}
        data-testid="popup-container"
        onClick={stopBubbling}
      >
        <div className={styles.triangle} style={triangleStyles} />
        <div className={styles.content}>{children}</div>
      </div>
    );
  }
);

Prompt.displayName = "Prompt";

export default Prompt;
