import React, { ComponentProps, FC } from "react";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./styles.module.scss";
import cx from "classnames";
import { Icon } from "@package/components";
import { useI18n } from "../../hooks";

type ILayout = React.PropsWithChildren<{
  className?: string;
  onBack?: () => void;
  headOptions?: ComponentProps<typeof Header>["optionsOrder"];
  fitToWindow?: boolean;
  stickyHeader?: boolean;
}>;

const Layout: FC<ILayout> & { headOptions: typeof Header.options } = ({
  children,
  className,
  onBack,
  headOptions,
  fitToWindow = false,
  stickyHeader = false,
}) => {
  const i18n = useI18n();

  return (
    <article className={styles.container}>
      <Header optionsOrder={headOptions} sticky={stickyHeader} />
      <main
        className={cx(
          styles.main,
          { [styles.fit_window]: fitToWindow },
          className
        )}
      >
        {onBack && (
          <button className={styles.back} onClick={onBack}>
            <Icon view={Icon.views.ARROW_BACK} />
            {i18n.common.back}
          </button>
        )}
        {children}
      </main>
      <Footer />
    </article>
  );
};

Layout.headOptions = Header.options;

export default Layout;
