import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  memo,
  useCallback,
} from "react";
import styles from "./style.module.scss";

interface ISwitchProperties {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: boolean;
  name?: string;
  disabled?: HTMLInputElement["disabled"];
}

const Switch: FC<ISwitchProperties> = ({ value, onChange, name, disabled }) => {
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { name, checked } }) =>
      onChange({
        target: { name, value: checked, checked },
      } as unknown as ChangeEvent<HTMLInputElement>),
    [onChange]
  );

  return (
    <label className={styles.switch} data-testid="container">
      <input
        className={styles.input}
        type="checkbox"
        data-testid="switch"
        onChange={onChangeHandler}
        checked={value}
        name={name}
        disabled={disabled}
      />
      <span className={styles.slider} />
    </label>
  );
};

export default memo(Switch);
