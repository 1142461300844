import React, { FC } from "react";
import { Icon } from "@package/components";
import cx from "classnames";
import styles from "./style.module.scss";
import { useI18n } from "../../hooks";
import { env } from "src/utils";

const LinkIcon: FC<{
  href: HTMLAnchorElement["href"];
  view: typeof Icon.views[keyof typeof Icon.views];
}> = ({ view, href }) => (
  <a className={styles.text} href={href} target="_blank" rel="noopener noreferrer">
    <Icon view={view} />
  </a>
);

const Footer: FC = () => {
  const i18n = useI18n();

  return (
    <section className={styles.container}>
      <div className={styles.block}>
        <div className={styles.column}>
          <Icon view={Icon.views.LOGO} />
          <span className={cx(styles.text, styles.power)}>{i18n.application.power_by}</span>
        </div>

        <div className={cx(styles.column, styles.socialColumn)}>
          <div className={styles.social_container}>
            <LinkIcon view={Icon.views.IN} href={env.LINKEDIN_URL} />
            <LinkIcon view={Icon.views.FACEBOOK} href={env.FACEBOOK_URL} />
          </div>
         <div className={styles.terms}>
          <a
            href={i18n.bigUniverseLinks.termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
            className={cx(styles.text, styles.blue)}
          >
            {i18n.application.termsAndConditions}
          </a>
          <a
            href={i18n.bigUniverseLinks.privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            className={cx(styles.text, styles.blue)}
          >
            {i18n.application.privacyPolicy}
          </a>
          </div>
          <span className={styles.text}>{i18n.application.applicationLicence}</span>
        </div>

        <div className={cx(styles.column, styles.addressWrapper)}>
          <address className={cx(styles.text, styles.address)}>
            {i18n.application.addressStreet}
            <br />
            &nbsp; &nbsp; &nbsp;{i18n.application.addressPostcode}
          </address>

          <a className={cx(styles.text, styles.blue)} href={env.MAIL_URL}>
            info@Admintech.ch
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
