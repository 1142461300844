import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ITranslationKey } from "../../i18n";
import { utils as sdkUtils } from "@big-proxy/sdk";

type IInitialState = {
  language: ITranslationKey;
  countryId: null | number;
};

const initialState: IInitialState = {
  countryId: null,
  language: "EN",
};

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<ITranslationKey>) {
      sdkUtils.language.acceptedLanguage.language = action.payload;
      return { ...state, language: action.payload };
    },
    setCountryId(state, action: PayloadAction<IInitialState["countryId"]>) {
      return { ...state, countryId: action.payload };
    },
  },
});

export default settings;
