import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  useCallback,
  useMemo,
} from "react";
import moment from "moment";
import { createPortal } from "react-dom";
import { always } from "../../utils";
import Picker from "./Picker";
import DateInput from "./DateInput";
import { useToggle } from "../../hooks/useToggle";
import { useDimensions } from "../../hooks/useDimensions";
import { useKeyPress } from "../../hooks/useKeyPress";

export type IDatepickerProps = {
  placeholder?: HTMLInputElement["placeholder"];
  className?: HTMLInputElement["className"];
  name?: HTMLInputElement["name"];
  title?: HTMLDivElement["title"];
  disabled?: HTMLInputElement["disabled"];
  value?: string | null;
  dateFormat?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const Datepicker: FC<IDatepickerProps> = ({
  placeholder = "",
  title = "",
  className = "",
  value = "",
  onChange = always.EMPTY_FUNC,
  dateFormat = "DD.MM.YYYY",
  name,
  disabled,
}: IDatepickerProps) => {
  const [openedPicker, setOpenedPicker] = useToggle(false);
  const [ref, bounds] = useDimensions<HTMLDivElement>(setOpenedPicker.off);

  const _date = useMemo(() => moment.utc(value), [value]);

  useKeyPress("Escape", setOpenedPicker.off);

  const onChangeHandler = useCallback(
    (value: moment.Moment | string) => {
      const newDate = moment.utc(value, dateFormat);
      const isValid = newDate.isValid();

      onChange({
        target: { value: isValid ? newDate.toISOString() : null, name },
      } as ChangeEvent<HTMLInputElement>);
    },
    [onChange, name]
  );

  return (
    <>
      <DateInput
        ref={ref}
        placeholder={placeholder}
        title={title}
        className={className}
        value={_date}
        dateFormat={dateFormat}
        name={name}
        onChange={onChangeHandler}
        onOpen={setOpenedPicker.on}
        disabled={disabled}
      />
      {openedPicker &&
        createPortal(
          <Picker
            value={_date}
            dateFormat={dateFormat}
            bounds={bounds}
            onChange={onChangeHandler}
            onClose={setOpenedPicker.off}
            inputRef={ref}
          />,
          document.body
        )}
    </>
  );
};

export default Datepicker;
