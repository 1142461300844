import React, { FC } from "react";
import { StripeCardElement } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import { env } from "src/utils";
import { useI18n } from "src/hooks";
import * as services from "../../service";
import { Button, always } from "@package/components";
import { getAllSubscriptions } from "src/service/payment";
import { subscription } from "@big-proxy/generics";

import stylesPay from "./Payment.module.scss";

type CardModule = { brand: string; last4: string; exp_month: string | number; exp_year: string | number; };

const changeStatusBasketItems = (ext?: { [key: string]: any }) =>
  services.payment.changeStatusBasketItems({ ...ext }).then(({ data }) => data.message);
const addItems = (ext?: { [key: string]: any }) =>
  services.payment.addItems({ ...ext }).then(({ data }) => data.message);

export type IPaymentForm = {
  secret: string,
  card: StripeCardElement,
  typePayment: string,
  onError: () => void,
  onClose: () => void;
  setDocuments?: (documents: number) => void;
  onChangePaymentMethod: () => void;
};
const { PaymentInfo } = subscription;

const PaymentForm: FC<IPaymentForm> = ({
  card: cardElement,
  secret,
  typePayment,
  onError,
  onClose,
  setDocuments,
  onChangePaymentMethod,
}) => {
  const stripe = useStripe();
  const i18n = useI18n();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe) {
      return null;
    }
    try {
      const result = await stripe.confirmCardPayment(secret, {}).then((data) => data);
      if (result.error) {
        onError();
        return;
      }
      if (typePayment === 'basket' && result.paymentIntent.status === 'succeeded') {
        await changeStatusBasketItems({ secret });
        window.location.href = window.location.origin + '/#/profile/documents';
        window.location.reload();
      }
      if (typePayment === 'add-more' && result.paymentIntent.status === 'succeeded') {
        await addItems({ secret });
        const { data } = await getAllSubscriptions();
        setDocuments && setDocuments(data.message.documents);
      }
      onClose();
    } catch (error) {
      onError();
      // eslint-disable-next-line no-console
      console.error(env.IS_DEVELOPMENT ? error : "Payment process error");
    }
  };
  const cardModule = cardElement as unknown as CardModule;
  return (
    <>
      <form onSubmit={handleSubmit} className={stylesPay.payment_form}>
        <PaymentInfo paymentInfo={cardModule} translation={{
          expires: i18n.profile.billing.paymentMethod.expires,
          ending: i18n.profile.billing.paymentMethod.ending,
          change: i18n.profile.billing.paymentMethod.change,
        }}
          onChangePaymentMethod={onChangePaymentMethod}
          className={stylesPay.information}
        />
        <Button
          type="submit"

          onClick={always.EMPTY_FUNC}
          className={stylesPay.pay_btn}
        >
          {i18n.payment.pay}
        </Button>
      </form>
    </>

  );
};

export default PaymentForm;
