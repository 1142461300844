import { get, post, put, remove } from "./api";

export const getRoles = (countryId: string) =>
  get({ url: "/users/roles/" + countryId });

export const getUsers = (countryId: string, query: string) =>
  get({ url: "/users/all/" + countryId + "?" + query });

export const createUser = (countryId: string, body: Record<string, any>) =>
  post({ url: "/users/add/" + countryId, body });

export const updateUser = (id: string, body: Record<string, any>) =>
  put({ url: "/users/update/" + id, body });
  
export const deleteUser = (id: string) =>
  remove({ url: "/users/remove/" + id  });

