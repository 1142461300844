import React, {
  ChangeEvent,
  ChangeEventHandler,
  memo,
  useCallback,
} from "react";
import { always } from "../../utils";
import styles from "./style.module.scss";

interface Props {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value: HTMLInputElement["value"];
  valueKey: HTMLInputElement["value"];
  name?: HTMLInputElement["name"];
  disabled?: HTMLInputElement["disabled"];
}

const Radio = ({
  name,
  value,
  disabled,
  valueKey,
  onChange = always.EMPTY_FUNC,
}: Props) => {
  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { name, checked } }) =>
      onChange({
        target: { name, value: valueKey, checked },
      } as unknown as ChangeEvent<HTMLInputElement>),
    [onChange, valueKey]
  );

  return (
    <label className={styles.container} data-testid="container">
      <input
        className={styles.item}
        type="radio"
        data-testid="radio"
        name={name}
        value={value}
        disabled={disabled}
        checked={valueKey === value}
        onChange={onChangeHandler}
      />
      <span className={styles.checkmark} data-testid="checkmark" />
    </label>
  );
};

export default memo(Radio);
