/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { useDynamicReducer } from "./useDynamicReducer";
import { ChangeEvent, ChangeEventHandler, useCallback, useMemo } from "react";
import { useStore, batch, useSelector } from "react-redux";

const getFormStore = <T>(name: string, initialValues: T) =>
  createSlice({
    name,
    initialState: {
      values_snapshot: initialValues,
      values: initialValues,
      validation: {},
      errors: {},
    },
    reducers: {
      setValues: (state, actions: PayloadAction<T>) => {
        state.values = actions.payload as Draft<T>;
      },
      setValuesSnapshot: (state, actions: PayloadAction<T>) => {
        state.values_snapshot = actions.payload as Draft<T>;
      },
      setValidation: (state, actions: PayloadAction<any>) => {
        state.validation = actions.payload;
      },
      setErrors: (state, actions: PayloadAction<any>) => {
        state.errors = actions.payload;
      },
    },
  });

const selectors = {
  getValues: (state: any, name: string) => state?.[name]?.values,
  getValuesSnapshot: (state: any, name: string) =>
    state?.[name]?.values_snapshot,
  getValidation: (state: any, name: string) => state?.[name]?.validation,
};

type ILoader<T> = () => Promise<T>;
type ISaver<T> = (
  entity: T
) => Promise<{ data: T; validation?: Record<string, any> }>;
type IOptions<T> = {
  loader?: ILoader<T>;
  saver?: ISaver<T>;
};
const defaultLoader: ILoader<null> = () => Promise.resolve(null);
const defaultSaver: ISaver<null> = (entity: null) =>
  Promise.resolve({ data: entity });

export const useFormStore = <S extends Slice, T>(
  store: S,
  options: IOptions<T>
) => {
  const { loader = defaultLoader, saver = defaultSaver } = options;

  useDynamicReducer(store.name, store.reducer);
  const { dispatch, getState } = useStore();

  const onInitialize = useCallback(() => {
    loader().then((response) => {
      batch(() => {
        dispatch(store.actions.setValues(response));
        dispatch(store.actions.setValuesSnapshot(response));
      });
    });
  }, []);

  const values =
    useSelector((state) => selectors.getValues(state, store.name)) ||
    selectors.getValues({ state: store.getInitialState() }, "state");

  const validation =
    useSelector((state) => selectors.getValidation(state, store.name)) ||
    selectors.getValidation({ state: store.getInitialState() }, "state");

  const onChange: ChangeEventHandler = useCallback(
    (event) => {
      const {
        target: { value: _values },
      } = event as unknown as ChangeEvent<HTMLInputElement>;
      dispatch(store.actions.setValues(_values));
    },
    [dispatch]
  );

  const onSave = useCallback(() => {
    const state = getState();
    const values = selectors.getValues(state, store.name);
    saver(values).then(({ data, validation = {} }) => {
      batch(() => {
        dispatch(store.actions.setValues(data));
        dispatch(store.actions.setValuesSnapshot(data));
        dispatch(store.actions.setValidation(validation));
      });
    });
  }, [dispatch]);

  return {
    store,
    values,
    validation,
    onChange,
    onInitialize,
    onSave,
  };
};

useFormStore.selectors = selectors;
useFormStore.getFormStore = getFormStore;

export const useForm = <T>(
  name: string,
  initialValues: T,
  options: IOptions<T>
) => {
  const store = useMemo(() => getFormStore<T>(name, initialValues), [name]);
  return useFormStore(store, options);
};

useForm.selectors = selectors;
