import { _fetch } from "./api";
import { BIG_PROXY_SERVICE, BIG_PROXY_PROJECT_ID } from "src/utils/env";

const BIG_PROXY_PRO_ID = String(BIG_PROXY_PROJECT_ID);

export const delete_account = () =>
  Promise.resolve({
    response: new Response(),
    data: {
      message: {
        error: false,
        message: {
          deleted: true,
        },
      },
      error: false,
    },
  });

export const update_profile = (baseUrl: string, query: any, body: Record<string, any>) =>
  _fetch({
    baseUrl,
    url: "/profile/user" + "?" + new URLSearchParams(query),
    body,
    method: "PUT",
  });

export const get_profile = () =>
  _fetch({  baseUrl: BIG_PROXY_SERVICE , url: "/profile/user" + "?" + new URLSearchParams({ projectId:  BIG_PROXY_PRO_ID }), method: "GET" });
