import {get, remove, put, request_fetch} from "./api";

export const getBasketItems = async (userId: string) =>
  get({ url: "/documents/getBasket/" + userId });

export const removeBasketItem = async (userId: string, id: string | number) =>
  remove({ url: "/documents/getBasket/" + userId, body: { id } });

export const setPaymentDocuments = async (userId: string, ids: string, sessionId: string) =>
  request_fetch.put("/payments/basketItems", { userId, ids, sessionId });

export const removeDocumentsFromBasket = async (
  documentId: string,
  userId: string
) => put({ url: "/documents/removeBasket/" + documentId, body: { userId } });
