export type placements =
  | "tl"
  | "top"
  | "tr"
  | "rt"
  | "right"
  | "rb"
  | "bl"
  | "bottom"
  | "br"
  | "lt"
  | "left"
  | "lb";

export interface IPosition {
  borderLeft: string;
  borderRight: string;
  borderTop: string;
  borderBottom: string;
  triangleTop: number;
  triangleLeft: number;
  padding: string;
  top: number;
  left: number;
}

export const getPosition = (
  placement: placements,
  elRect: ClientRect,
  targetRect: ClientRect,
  triangleSize: number
) => {
  switch (placement) {
    case "tl":
    case "top":
    case "tr":
      return getTopPosition(elRect, targetRect, placement, triangleSize);
    case "rt":
    case "right":
    case "rb":
      return getRightPosition(elRect, targetRect, placement, triangleSize);
    case "bl":
    case "bottom":
    case "br":
      return getBottomPosition(elRect, targetRect, placement, triangleSize);
    case "lt":
    case "left":
    case "lb":
      return getLeftPosition(elRect, targetRect, placement, triangleSize);
    default:
      return getBottomPosition(elRect, targetRect, placement, triangleSize);
  }
};

const getTopPosition = (
  elRect: ClientRect,
  targetRect: ClientRect,
  placement: placements,
  triangleSize: number
): IPosition => {
  switch (placement) {
    case "tl":
      return {
        left: targetRect.left,
        top: targetRect.top - elRect.height,
        padding: `0 0 ${triangleSize}px 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `${triangleSize}px solid white`,
        borderBottom: `none`,
        triangleTop: elRect.height - triangleSize,
        triangleLeft: triangleSize,
      };
    case "top":
      return {
        left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
        top: targetRect.top - elRect.height,
        padding: `0 0 ${triangleSize}px 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `${triangleSize}px solid white`,
        borderBottom: "none",
        triangleTop: elRect.height - triangleSize,
        triangleLeft: elRect.width / 2 - triangleSize,
      };
    case "tr":
      return {
        left: targetRect.left + targetRect.width - elRect.width,
        top: targetRect.top - elRect.height,
        padding: `0 0 ${triangleSize}px 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `${triangleSize}px solid white`,
        borderBottom: "none",
        triangleTop: elRect.height - triangleSize,
        triangleLeft: elRect.width - triangleSize * 3,
      };
    default:
      return {
        left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
        top: targetRect.top - elRect.height,
        padding: `0 0 ${triangleSize}px 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `${triangleSize}px solid white`,
        borderBottom: "none",
        triangleTop: elRect.height - triangleSize,
        triangleLeft: elRect.width / 2 - triangleSize,
      };
  }
};

const getRightPosition = (
  elRect: ClientRect,
  targetRect: ClientRect,
  placement: placements,
  triangleSize: number
): IPosition => {
  switch (placement) {
    case "rt":
      return {
        left: targetRect.left + targetRect.width,
        top: targetRect.top,
        padding: `0 0 0 ${triangleSize}px`,
        borderLeft: `none`,
        borderRight: `${triangleSize}px solid white`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: triangleSize,
        triangleLeft: 0,
      };
    case "right":
      return {
        left: targetRect.left + targetRect.width,
        top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
        padding: `0 0 0 ${triangleSize}px`,
        borderLeft: `none`,
        borderRight: `${triangleSize}px solid white`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height / 2 - triangleSize,
        triangleLeft: 0,
      };
    case "rb":
      return {
        left: targetRect.left + targetRect.width,
        top: targetRect.top + targetRect.height - elRect.height,
        padding: `0 0 0 ${triangleSize}px`,
        borderLeft: `none`,
        borderRight: `${triangleSize}px solid white`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height - triangleSize * 3,
        triangleLeft: 0,
      };
    default:
      return {
        left: targetRect.left + targetRect.width,
        top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
        padding: `0 0 0 ${triangleSize}px`,
        borderLeft: `none`,
        borderRight: `${triangleSize}px solid white`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height / 2 - triangleSize,
        triangleLeft: 0,
      };
  }
};

const getBottomPosition = (
  elRect: ClientRect,
  targetRect: ClientRect,
  placement: placements,
  triangleSize: number
): IPosition => {
  switch (placement) {
    case "bl":
      return {
        left: targetRect.left,
        top: targetRect.top + targetRect.height,
        padding: `${triangleSize}px 0 0 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `none`,
        borderBottom: `${triangleSize}px solid white`,
        triangleTop: 0,
        triangleLeft: triangleSize,
      };
    case "bottom":
      return {
        left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
        top: targetRect.top + targetRect.height,
        padding: `${triangleSize}px 0 0 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `none`,
        borderBottom: `${triangleSize}px solid white`,
        triangleTop: 0,
        triangleLeft: elRect.width / 2 - triangleSize,
      };
    case "br":
      return {
        left: targetRect.left + targetRect.width - elRect.width,
        top: targetRect.top + targetRect.height,
        padding: `${triangleSize}px 0 0 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `none`,
        borderBottom: `${triangleSize}px solid white`,
        triangleTop: 0,
        triangleLeft: elRect.width - triangleSize * 3,
      };
    default:
      return {
        left: targetRect.left + targetRect.width / 2 - elRect.width / 2,
        top: targetRect.top + targetRect.height,
        padding: `${triangleSize}px 0 0 0`,
        borderLeft: `${triangleSize}px solid transparent`,
        borderRight: `${triangleSize}px solid transparent`,
        borderTop: `none`,
        borderBottom: `${triangleSize}px solid white`,
        triangleTop: 0,
        triangleLeft: elRect.width / 2 - triangleSize,
      };
  }
};

const getLeftPosition = (
  elRect: ClientRect,
  targetRect: ClientRect,
  placement: placements,
  triangleSize: number
): IPosition => {
  switch (placement) {
    case "lt":
      return {
        left: targetRect.left - elRect.width,
        top: targetRect.top,
        padding: `0 ${triangleSize}px 0 0`,
        borderLeft: `${triangleSize}px solid white`,
        borderRight: `none`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: triangleSize,
        triangleLeft: elRect.width - triangleSize,
      };
    case "left":
      return {
        left: targetRect.left - elRect.width,
        top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
        padding: `0 ${triangleSize}px 0 0`,
        borderLeft: `${triangleSize}px solid white`,
        borderRight: `none`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height / 2 - triangleSize,
        triangleLeft: elRect.width - triangleSize,
      };
    case "lb":
      return {
        left: targetRect.left - elRect.width,
        top: targetRect.top + targetRect.height - elRect.height,
        padding: `0 ${triangleSize}px 0 0`,
        borderLeft: `${triangleSize}px solid white`,
        borderRight: `none`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height - triangleSize * 3,
        triangleLeft: elRect.width - triangleSize,
      };
    default:
      return {
        left: targetRect.left - elRect.width,
        top: targetRect.top + targetRect.height / 2 - elRect.height / 2,
        padding: `0 ${triangleSize}px 0 0`,
        borderLeft: `${triangleSize}px solid white`,
        borderRight: `none`,
        borderTop: `${triangleSize}px solid transparent`,
        borderBottom: `${triangleSize}px solid transparent`,
        triangleTop: elRect.height / 2 - triangleSize,
        triangleLeft: elRect.width - triangleSize,
      };
  }
};
