import React from "react";
import ReactDOM from "react-dom/client";
import Application from "./Application";
import { Provider as Redux } from "react-redux";
import { Provider as I18n } from "./i18n";
import { SpriteProvider } from "@package/components";
import CallbackProvider from "./modules/Callbacks";
import { initConfig } from "@big-proxy/sdk";
import { env } from "./utils";

import store from "./store";

import "./styles/global.scss";

// initConfig({
//   apiHost: env.BIG_PROXY_SERVICE,
//   projectId: env.BIG_PROXY_PROJECT_ID,
//   keySessionToken: "token",
// });

const rootElement = document.getElementById("root") as Element;

const root = ReactDOM.createRoot(rootElement);
root.render(
  <SpriteProvider>
    <Redux store={store}>
      <I18n>
        <CallbackProvider>
          <Application />
        </CallbackProvider>
      </I18n>
    </Redux>
  </SpriteProvider>
);
