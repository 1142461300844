import React, { FC, useCallback, useContext } from "react";
import { useI18n } from "src/hooks";
import { Button, Icon, Modal as ModalComponent, useModal } from "@package/components";
import DeleteDocumentModal from "src/modules/Profile/Documents/DeleteDocumentModal";
import { ls } from "src/utils";
import * as services from "src/service";
import cx from "classnames";
import { authorization } from "src/store";
import { useSelector } from "react-redux";

import styles from "./styles.module.scss";
import {EntitiesBasket} from "../entitesBasket";
import {IBasketItem} from "../types";

export const SUM_AMOUNTS = (array: number[]): number =>
  Number(array.reduce((a, i) => a + i, 0).toFixed(2));

interface IProp {
  onClose: () => void;
  onReloadBasketItems: () => void;
  openCheckoutModal: () => void;
}

const BasketModal: FC<IProp> = ({
  onReloadBasketItems,
  onClose: onCloseHandler,
  openCheckoutModal,
}) => {
  const entities = useContext(EntitiesBasket) as IBasketItem[];
  const i18n = useI18n();
  const currency = entities?.[0]?.currency?.toUpperCase();
  const outPrice = SUM_AMOUNTS(entities.map(({ amount }) => amount));
  const user = useSelector(authorization.selectors.getUser);

  const onRemoveItem = useCallback(
    async (id: string | number) => {
      const _userId = user.userId || ls.userId.get();
      await services.basket.removeBasketItem(_userId, id);
      onReloadBasketItems();
    },
    [onReloadBasketItems, user]
  );

  const [fragment, onOpen, onClose] = useModal(DeleteDocumentModal, {
    modalProps: { title: i18n.profile.delete_document },
    modalComponent: ModalComponent.Headered,
  });

  const onRemoveItemModalCall: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    ({ target }) => {
      const id = (target as HTMLButtonElement).value;
      return onOpen({
        id,
        onClose,
        onDelete: onRemoveItem,
      });
    },
    [onClose, onRemoveItem]
  );

  const openCheckoutModalHandle = useCallback(() => {
    onCloseHandler();
    openCheckoutModal();
  }, []);

  return (
    <>
      <div className={styles.btnContainer}>
        <Button.Icon view={Icon.views.DELETE} onClick={onCloseHandler} className={styles.close} />
      </div>
      <div className={styles.frame}>
        <h1 className={styles.title}>{i18n.application.order_summary_title}</h1>

        <div className={cx(styles.table, styles.head)}>
          <p className={styles.header}>{i18n.application.product_label}</p>
          <p className={styles.header}>{i18n.application.subtotal_label}</p>
          <span className={styles.header} />
        </div>

        <div className={cx(styles.table, styles.body)}>
          {entities.map((item) => (
            <React.Fragment key={item.id}>
              <p className={styles.body}>{item.documentName || item.templateName}</p>
              <p className={cx(styles.body, styles.price)}>
                {item.currency.toUpperCase()} {item.amount}
              </p>
              <Button.Icon
                view={Icon.views.DELETE}
                value={item.id}
                className={cx(styles.body, styles.button)}
                onClick={onRemoveItemModalCall}
              />
            </React.Fragment>
          ))}
        </div>

        <span />
        <p className={styles.total}>
          {i18n.application.total_label} {currency} {outPrice}
        </p>
        {fragment}

        <div className={cx(styles.row, styles.buttons_bar)}>
          <Button
            border={Button.borders.HavelockBlue}
            color={Button.colors.White}
            fill={Button.fills.HavelockBlue}
            className={cx(styles.button, styles.button_process)}
            onClick={openCheckoutModalHandle}
          >
            {i18n.application.process_order}
          </Button>
          <Button
            border={Button.borders.HavelockBlue}
            color={Button.colors.HavelockBlue}
            className={cx(styles.button, styles.button_back)}
            onClick={onCloseHandler}
          >
            {i18n.application.back_to_purchases}
          </Button>
        </div>
      </div>
    </>
  );
};

export default BasketModal;
