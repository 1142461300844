import React, { FC, useState } from "react";
import TabsStateless, { ITabsStateless } from "./Tabs.stateless";

export type ITabs = Omit<ITabsStateless, "onChange" | "currentTab"> & {
  defaultTab?: number | string;
};

const Tabs: FC<ITabs> & { Stateless: typeof TabsStateless } = ({
  defaultTab = 0,
  ...props
}) => {
  const [previousTab, setPreviousTab] = useState<string | number>(defaultTab);
  return (
    <TabsStateless
      currentTab={previousTab}
      onChange={setPreviousTab}
      {...props}
    />
  );
};

Tabs.Stateless = TabsStateless;

export default Tabs;
