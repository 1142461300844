import { get, post, put, remove, _fetch, request_fetch } from "./api";
import { env } from "src/utils";
import { Service as sdkService } from "@big-proxy/sdk";

const BIG_PROXY_PROJECT_ID = String(env.BIG_PROXY_PROJECT_ID);
const BIG_PROXY_URL = env.BIG_PROXY_SERVICE;

export const createPaymentIntent = ({
  userId,
  ext,
}: {
  userId?: string;
  ext?: { [key: string]: any };
}) => post({ url: "/payments/pay/" + userId, body: { ...ext } });

export const createPaymentInvoice = (body: { [key: string]: any }) =>
  post({ url: "/payments/invoice/", body });

export const createSubscription = async (priceId: string, isTrial?: boolean) => {
  const response = await post<any, any, { error: boolean; message: any }>({
    url: "/payments/subscription",
    body: { priceId, isTrial },
  });

  if (response.response.ok) {
    sdkService.dataLayer.handleCreateSubscription(
      sdkService.dataLayer.handleCreateSubscription.transformSubscriptionToDataLayerItem(
        response.data.message
      )
    );
  }

  return response;
};

export const updateSubscription = ({
  priceId,
  finishTrial,
  reactivate,
  repayNow,
}: {
  priceId?: string;
  finishTrial?: boolean;
  reactivate?: boolean;
  repayNow?: boolean;
}) =>
  put({
    url: "/payments/subscription",
    body: { priceId, finishTrial, reactivate, repayNow },
  }).then((response) => {
    if (response.response.ok) {
      sdkService.dataLayer.handleModifySubscription(
        sdkService.dataLayer.handleModifySubscription.transformSubscriptionToDataLayerItem(
          response.data.message,
          response.data.meta as any
        )
      );
    }
    return response;
  });

export const removeSubscription = () => remove({ url: "/payments/subscription" });

export const getAllSubscriptions = () => get({ url: "/payments/subscriptions" });

export const getIsUsedTrialSubscription = ({ priceId }: { priceId: string }) =>
  request_fetch.get("/payments/subscription-trial-used", {
    projectId: BIG_PROXY_PROJECT_ID,
    priceId,
  });

export const changeStatusBasketItems = (ext: { [key: string]: string | number }) =>
  post({ url: "/payments/basketItems", body: { ...ext } });

export const addItems = (ext: { [key: string]: string | number }) =>
  post({ url: "/documents/addmore", body: { ...ext } });

export const getSubcriptions = () =>
  _fetch({
    baseUrl: BIG_PROXY_URL,
    url: "/payments/subscription" + "?" + new URLSearchParams({ projectId: BIG_PROXY_PROJECT_ID }),
    method: "GET",
  });

export const getPrices = () =>
  _fetch({
    baseUrl: BIG_PROXY_URL,
    url: "/payments/prices" + "?" + new URLSearchParams({ projectId: BIG_PROXY_PROJECT_ID }),
    method: "GET",
  });

export const unSubscribeSubscription = () =>
  _fetch({
    baseUrl: BIG_PROXY_URL,
    url: "/payments/subscription" + "?" + new URLSearchParams({ projectId: BIG_PROXY_PROJECT_ID }),
    method: "DELETE",
  });


export const getCheckoutSessionInfo = (sessionId: string) =>
  request_fetch.get("/payments/checkout-session-info", {
    sessionId,
    projectId: BIG_PROXY_PROJECT_ID,
   
  });
