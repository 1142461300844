import React, { ButtonHTMLAttributes, FC, MouseEventHandler } from "react";
import { always } from "../../utils";
import cx from "classnames";

import styles from "./button.module.scss";

const Colors = {
  DarkBlueGrey: "dark-blue-grey",
  GullGray: "gull-gray",
  Coral: "coral",
  BigStone: "big-stone",
  HavelockBlue: "havelock-blue",
  Carnation: "carnation",
  White: "white",
  Picasso: "picasso",
  Transparent: "transparent",
} as const;

const Fills = {
  DarkBlueGrey: "dark-blue-grey",
  GullGray: "gull-gray",
  Coral: "coral",
  BigStone: "big-stone",
  HavelockBlue: "havelock-blue",
  Carnation: "carnation",
  White: "white",
  Picasso: "picasso",
  Transparent: "transparent",
} as const;

const Borders = {
  DarkBlueGrey: "dark-blue-grey",
  GullGray: "gull-gray",
  Coral: "coral",
  BigStone: "big-stone",
  HavelockBlue: "havelock-blue",
  Carnation: "carnation",
  White: "white",
  Picasso: "picasso",
  Transparent: "transparent",
} as const;

const ViewType = {
  Rounded: "rounded",
  Rectangle: "rectangle",
} as const;

export interface IButton {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  value?: HTMLButtonElement["value"];
  name?: HTMLButtonElement["name"];
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: HTMLButtonElement["disabled"];
  className?: HTMLButtonElement["className"];
  fill?: ValueOf<typeof Fills>;
  color?: ValueOf<typeof Colors>;
  border?: ValueOf<typeof Borders>;
  viewType?: ValueOf<typeof ViewType>;
  children: React.ReactNode;
}

const Button: FC<IButton> & {
  fills: typeof Fills;
  borders: typeof Borders;
  colors: typeof Colors;
  viewType: typeof ViewType;
} = ({
  onClick = always.EMPTY_FUNC,
  value,
  name,
  disabled,
  fill = Fills.Transparent,
  color = Colors.DarkBlueGrey,
  border = Borders.DarkBlueGrey,
  viewType = ViewType.Rounded,
  className,
  children = "Default text",
  type = "button",
}) => {
  return (
    <button
      className={cx(
        styles.container,
        {
          [styles[`color-${color}`]]: color,
          [styles[`fill-${fill}`]]: fill,
          [styles[`border-${border}`]]: border,
          [styles[`type-${viewType}`]]: viewType,
        },
        className
      )}
      data-testid="button"
      onClick={onClick}
      value={value}
      name={name}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.displayName = "Button";
Button.borders = Borders;
Button.fills = Fills;
Button.colors = Colors;
Button.viewType = ViewType;

export default Button;
