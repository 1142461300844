import React, { FC } from "react";
import { useToggle } from "../../hooks";
import AccordionStateless, { IAccordionStateless } from "./Accordion.stateless";

export type IAccordion = Omit<
  IAccordionStateless,
  "isCollapsed" | "onClickCollapse"
> & {
  isDefaultCollapsed?: boolean;
};

const Accordion: FC<IAccordion> & { Stateless: typeof AccordionStateless } = ({
  isDefaultCollapsed = true,
  children,
  ...props
}) => {
  const [isCollapsed, onClickCollapse] = useToggle(isDefaultCollapsed);

  return (
    <AccordionStateless
      isCollapsed={isCollapsed}
      onClickCollapse={onClickCollapse}
      {...props}
    >
      {children}
    </AccordionStateless>
  );
};

Accordion.Stateless = AccordionStateless;

export default Accordion;
