import React, { ChangeEvent, FC, memo, MouseEventHandler } from "react";
import { sortingContexts } from "../contexts";
import cx from "classnames";
import Icon from "../../Icon";

import styles from "./styles.module.scss";

type IDirections = "DESC" | "ASC" | null;
const directionSequence: IDirections[] = ["DESC", "ASC", null];

interface ISortButton {
  direction?: IDirections;
  valueKey: string;
}

const SortButton: FC<ISortButton> = ({ valueKey }) => {
  const { values, onChange } = React.useContext(sortingContexts);
  const isASC = values[valueKey] === "ASC";
  const isDESC = values[valueKey] === "DESC";

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    const target = event.target as HTMLButtonElement;
    const indexInSequence = directionSequence.indexOf(
      target.value as IDirections
    );
    const nextValue =
      indexInSequence <= directionSequence.length - 1
        ? directionSequence[indexInSequence + 1]
        : directionSequence[0];
    onChange({
      target: { name: valueKey, value: nextValue },
    } as unknown as ChangeEvent);
  };

  return (
    <div className={styles.sort}>
      <button
        type="button"
        className={styles.sort_button}
        value={values[valueKey] as string}
        name={valueKey}
        onClick={onClickHandler}
      >
        <Icon
          view={Icon.views.TRIANGLE_TOP_ARROW}
          className={cx(styles.triangle_icon, {
            [styles.triangle_icon_active]: isDESC,
          })}
        />
      </button>
      <button
        type="button"
        className={styles.sort_button}
        value={values[valueKey] as string}
        name={valueKey}
        onClick={onClickHandler}
      >
        <Icon
          view={Icon.views.TRIANGLE_BOTTOM_ARROW}
          className={cx(styles.triangle_icon, {
            [styles.triangle_icon_active]: isASC,
          })}
        />
      </button>
    </div>
  );
};

export default memo(SortButton);
