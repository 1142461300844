import store from "../store";
import { useLayoutEffect } from "react";

type StoreAttachParameters = Parameters<typeof store.reducerManager.attach>;

export const useDynamicReducer = (
  key: StoreAttachParameters[0],
  reducer: StoreAttachParameters[1],
  unmountDelay?: number
) => {
  useLayoutEffect(() => {
    store.reducerManager.attach(key, reducer);
    // NEED TO ATTACH reducer immediately
    store.dispatch({ type: "REDUCER_ATTACH", payload: key });
    return () => {
      store.reducerManager.detach(key, unmountDelay);
      // NEED TO DETACH reducer immediately
      store.dispatch({ type: "REDUCER_DETACH", payload: key });
    };
  }, []);
};
