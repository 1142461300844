import React, { ChangeEventHandler } from "react";
import { always } from "../../utils";

type ISortingContexts = {
  values: Record<string, unknown>;
  onChange: ChangeEventHandler;
};

const sortingContexts = React.createContext<ISortingContexts>({
  values: {},
  onChange: always.EMPTY_FUNC,
});

type IFilteringContexts = ISortingContexts;

const filteringContexts = React.createContext<IFilteringContexts>({
  values: {},
  onChange: always.EMPTY_FUNC,
});

export { sortingContexts, filteringContexts };
