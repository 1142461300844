import { useLayoutEffect } from "react";

type IKey = "Escape" | "Enter" | "Shift" | "ArrowDown" | "ArrowUp" | string;
export const useKeyPress = (key: IKey, handler: () => void) => {
  useLayoutEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === key) {
        handler();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
};

export default useKeyPress;
