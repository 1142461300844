/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEvent,
  useMemo,
  useRef,
  useDebugValue,
  useCallback,
  ChangeEventHandler,
} from "react";

export const useFormChange = <T extends Record<string, any>>(
  value: T,
  onChange: ChangeEventHandler,
  name = ""
) => {
  const ref = useRef<T>(value);

  ref.current = value;

  useDebugValue(value);

  const onChangeInputHandler: ChangeEventHandler = useCallback(
    (event) => {
      const {
        target: { value: v, name: n },
      } = event as ChangeEvent<HTMLInputElement>;
      onChange({
        target: { name, value: { ...ref.current, [n]: v } },
      } as unknown as ChangeEvent<HTMLInputElement>);
    },
    [ref, onChange]
  );

  return useMemo(
    () => ({
      input: (name: string) => ({
        value: ref.current?.[name],
        onChange: onChangeInputHandler,
        name,
      }),
    }),
    [onChange, ref]
  );
};

export const useFormArrayChange = <T extends Array<any>>(
  value: T,
  onChange: ChangeEventHandler,
  name = ""
) => {
  const ref = useRef<T>(value);

  ref.current = value;

  useDebugValue(value);

  const onChangeInputHandler: ChangeEventHandler = useCallback(
    (event) => {
      const {
        target: { value: v, name: n },
      } = event as ChangeEvent<HTMLInputElement>;
      const numIndex = Number(n);
      const items = ref.current.map((_, index) => (numIndex === index ? v : _));
      onChange({
        target: { name, value: items },
      } as unknown as ChangeEvent<HTMLInputElement>);
    },
    [ref, onChange]
  );

  return useMemo(
    () => ({
      array: (name: string) => ({
        value: ref.current?.[name as unknown as number],
        onChange: onChangeInputHandler,
        name,
      }),
    }),
    [onChange, ref]
  );
};
