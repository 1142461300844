/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "../../forms";
import React from "react";
import { ISelectProps } from "./Select.multi";
import cx from "classnames";

import styles from "./utils.module.scss";

export const defaultValueSelector = (option: any) => option?.label;

export const defaultIdSelector = (option: any) => option?.id;

export const defaultCreatableSelector = (query: string) => ({
  id: query,
  label: query,
});

export const defaultFilterSelector = (
  option: any,
  query: string,
  valueSelector: typeof defaultValueSelector
) => valueSelector(option).toLowerCase().indexOf(query.toLowerCase()) > -1;

export const defaultRenderTag = (
  item: any,
  onChangeHandler: (e: any) => void,
  disabled: boolean,
  valueSelector: ISelectProps["valueSelector"] = defaultValueSelector,
  idSelector: ISelectProps["idSelector"] = defaultIdSelector
) => (
  <div
    key={idSelector(item) as string}
    className={cx(styles.tag)}
    data-testid="select-tag"
  >
    {valueSelector(item)}
    <Button.Icon
      view={Button.Icon.views.CLOSE}
      disabled={disabled}
      onClick={() => onChangeHandler(item)}
      className={styles.remove}
    />
  </div>
);
