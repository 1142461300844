export const pdf = (base64: string, name: string, type = "pdf") => {
  const aTag = document.createElement("a");
  aTag.href = `data:application/${type};base64,${base64}`;
  aTag.download = `${name}.${type}`;

  return new Promise((resolve) => {
    setTimeout(function () {
      try {
        aTag.dispatchEvent(new MouseEvent("click"));
      } catch (e) {
        const evt = document.createEvent("MouseEvents");
        evt.initMouseEvent(
          "click",
          true,
          true,
          window,
          0,
          0,
          0,
          80,
          20,
          false,
          false,
          false,
          false,
          0,
          null
        );
        aTag.dispatchEvent(evt);
      } finally {
        resolve(true);
      }
    }, 0);
  });
};
