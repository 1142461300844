import {useResource} from "src/hooks";
import * as services from "src/service";
import type {IBasketItem} from "./types";

const useBasketItemsResource = () =>
  useResource<IBasketItem>("basket-items", async ({filter}) => {
    const response = await services.basket.getBasketItems(filter.userId);

    if (response.data.error) {
      throw new Error(JSON.stringify(response.data));
    }
    return {
      data: response.data.message,
      isLoadedAll: true,
    };
  });

export default useBasketItemsResource;
