import React, {
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useEffect,
  useCallback,
} from "react";
import moment from "moment";
import Button from "../../forms/Button";
import Input from "../Input";
import { always } from "../../utils";
import useToggle from "../../hooks/useToggle";

import cx from "classnames";
import styles from "./DateInput.module.scss";

export type IDateInputProps = {
  ref: React.RefObject<HTMLDivElement>;
  placeholder?: HTMLInputElement["placeholder"];
  name?: HTMLInputElement["name"];
  className?: HTMLInputElement["className"];
  disabled?: HTMLInputElement["disabled"];
  title?: HTMLDivElement["title"];
  value: moment.Moment;
  onChange?: (e: moment.Moment | string) => void;
  dateFormat?: string;
  onOpen?: () => void;
};

const DateInput = React.forwardRef<HTMLDivElement, IDateInputProps>(
  (
    {
      placeholder,
      title,
      className,
      value,
      name,
      dateFormat,
      onChange = always.EMPTY_FUNC,
      onOpen = always.EMPTY_FUNC,
      disabled = false,
    },
    ref
  ) => {
    const [currentDate, setCurrentDate] = useState(
      value.isValid() ? value.format(dateFormat) : ""
    );
    const [isFocused, setIsFocused] = useToggle(false);

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        setCurrentDate(e.target.value);
        onChange(e.target.value);
      },
      [onChange]
    );

    const onBlurHandler: FocusEventHandler<HTMLInputElement> = () => {
      setIsFocused.off();
    };

    const onFocusHandler: FocusEventHandler<HTMLInputElement> = () => {
      setIsFocused.on();
      onOpen();
    };

    useEffect(() => {
      if (!isFocused)
        setCurrentDate(value.isValid() ? value.format(dateFormat) : "");
    }, [value, isFocused]);

    return (
      <div
        className={cx(styles.container, className)}
        title={title}
        ref={ref}
        data-testid="input-container"
      >
        <Input
          value={currentDate}
          placeholder={placeholder}
          onChange={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          name={name}
          className={styles.input}
          disabled={disabled}
        />
        <Button.Icon
          view={Button.Icon.views.FULL_TRIANGLE_ARROW}
          onClick={onOpen}
          className={styles.arrowIcon}
          disabled={disabled}
        />
      </div>
    );
  }
);

DateInput.displayName = "DateInput";

export default DateInput;
