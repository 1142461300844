import React, { FC, memo, MouseEventHandler, useCallback } from "react";
import { createPortal } from "react-dom";
import { useDimensions, useToggle } from "../../hooks";
import Prompt from "./Prompt";
import { placements } from "./calculation";
import { always } from "../../utils";

export interface IPopoverProps {
  className?: HTMLInputElement["className"];
  children?: React.ReactNode;
  content: React.ReactNode;
  triangleSize?: number;
  placement?: placements;
}

const Popup: FC<IPopoverProps> = ({
  className,
  children,
  content,
  triangleSize = 10,
  placement = "right",
}) => {
  const [isOpenedPopup, setOpened] = useToggle(false);

  const [ref, bounds] = useDimensions<HTMLDivElement>();
  const [contentRef, contentBounds] = useDimensions<HTMLDivElement>(
    always.EMPTY_FUNC,
    isOpenedPopup
  );

  const onContainerClose: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const content = contentRef.current;
      if (!content || content.contains(event.relatedTarget as Node)) {
        return;
      }
      setOpened.off();
    },
    [setOpened, contentRef]
  );

  const onPopupClose: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const container = ref.current;
      if (!container || container.contains(event.relatedTarget as Node)) {
        return;
      }
      setOpened.off();
    },
    [setOpened, ref]
  );

  return (
    <>
      <div
        className={className}
        ref={ref}
        onMouseEnter={setOpened.on}
        onMouseLeave={onContainerClose}
      >
        {content}
      </div>
      {isOpenedPopup &&
        createPortal(
          <Prompt
            ref={contentRef}
            triangleSize={triangleSize}
            placement={placement}
            bounds={bounds}
            contentBounds={contentBounds}
            onPopupClose={onPopupClose}
          >
            {children}
          </Prompt>,
          document.body
        )}
    </>
  );
};

export default memo(Popup);
