import { get, post, remove } from "./api";

export const getAllTemplates = (countryId: string, query: string) =>
  get({ url: "/templates/all?" + query });

export const getListOfTemplateStatuses = () =>
  get({ url: "/templates/statuses" });

export const setStatusToTemplate = (templateId: string, statusId: number) =>
  post({ url: "/templates/setStatus/" + templateId + "/" + statusId });

export const updateLinksToTemplate = (
  templateId: string,
  body: Record<string, any>
) => post({ url: "/templates/addLinks/" + templateId, body });

export const removeTemplates = (id: string) =>
  remove({ url: "/templates/remove/" + id });

export const copyTemplates = (id: string) =>
  post({ url: "/templates/copy/" + id });
