import { get, put } from "./api";

export const getCountries = () =>
  get({ url: "/countryLanguages/allCountries" });

export const getLanguages = () =>
  get({ url: "/countryLanguages/allLanguages" });

export const getCountryLanguages = () =>
  get({ url: "/countryLanguages/getCountryLanguages" });

export const updateCountryLanguages = (body: any) =>
  put({ url: "/countryLanguages/updateCountryLanguages", body });
