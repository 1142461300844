import React, { FC, useState } from "react";
import styles from "./CountryAndLanguageMobile.module.scss";
import cx from "classnames";
import { Icon, Button } from "@package/components";
import { ICountryLanguageResource } from "../../hooks/useResource/useCountryLanguageResource";
import { useI18n } from "src/hooks/useI18n";
import { countryFlags } from "./utils/constants";

interface ICountryAndLanguageMobile {
  countriesOptions: ICountryLanguageResource[];
  countriesOption: ICountryLanguageResource | undefined;
  onSelectCountry: (event: React.MouseEvent<HTMLButtonElement>) => void;
  languagesOptions: { short: string; long: Record<string, string>; id: number }[];
  languagesOption: { short: string; long: Record<string, string>; id: number } | undefined;
  onSelectLanguage: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const CountryAndLanguageMobile: FC<ICountryAndLanguageMobile> = ({
  countriesOptions,
  countriesOption,
  onSelectCountry,
  languagesOptions,
  languagesOption,

  onSelectLanguage,
}) => {
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const i18n = useI18n();

  const toggleCountry = () => setIsOpenCountry(!isOpenCountry);
  const toggleLanguage = () => {
    if (languagesOptions.length === 0) return;
    setIsOpenLanguage(!isOpenLanguage);
  };

  return (
    <>
      <div className={styles.countries}>
        <Button className={styles.seletedButton} onClick={toggleCountry}>
          <div className={styles.selected}>
            <Icon view={Icon.views.GLOBE} />
            <p className={styles.text}>{countriesOption?.countryName.EN}</p>
          </div>
          <Icon
            view={Icon.views.ARROW_GR}
            className={styles.btnArrow}
          />
        </Button>
        <div className={cx(styles.options, { [styles.activeOptions]: isOpenCountry })}>
          <div className={styles.iconWrapper}>
            <Button onClick={toggleCountry} name="arrow-back" className={styles.btn} >
              <Icon view={Icon.views.ARROW_BACK} />
              <span className={styles.btnArrowBack}>{i18n.common.back}</span>
            </Button>
          </div>

          {countriesOptions.map(({ countryName, countryId }) => (
            <div key={countryId} className={styles.optionsWrapper}>
              <div>{countryFlags[countryName.EN]}</div>
              <button className={styles.option} value={countryId} onClick={(event) => {
                onSelectCountry(event)
                toggleCountry()
              }}>
                {countryName.EN}
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.languages} aria-disabled={languagesOptions.length === 0}>
        <Button onClick={toggleLanguage} className={styles.seletedButton}>
          <div className={styles.selected}>
            <p className={styles.text}>{languagesOption?.short}</p>
          </div>
          <Icon
            view={Icon.views.ARROW_GR}
            className={styles.btnArrow}
          />
        </Button>
        <div className={cx(styles.options, { [styles.activeOptions]: isOpenLanguage })}>
          <div className={styles.iconWrapper}>
            <Button onClick={toggleLanguage} name="arrow-back" className={styles.btn} >
              <Icon view={Icon.views.ARROW_BACK} />
              <span className={styles.btnArrowBack}>{i18n.common.back}</span>
            </Button>
          </div>
          {languagesOptions.map(({ short }) => (
            <button className={styles.option} key={short} value={short} onClick={(event) => {
              onSelectLanguage(event);
              toggleLanguage();
            }}>
              {short}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default CountryAndLanguageMobile;
