/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AnyObjectSchema } from "yup";
import set from "lodash/set";
import ValidationError from "yup/lib/ValidationError";

const _inlineToObjectTree = (errors: ValidationError[]) => {
  const object = {};
  errors.forEach(({ path, message }) => {
    set(object, path as string, message);
  });
  return object;
};

export const normalizedValidation = async <T = any>(
  schema: AnyObjectSchema,
  data: T
) => {
  return schema
    .validate(data, { abortEarly: false })
    .then((value) => ({ success: true, value }))
    .catch(({ inner }) => ({
      success: false,
      value: _inlineToObjectTree(inner),
    }));
};
