import { post, remove, request_fetch } from "./api";
import { env } from "src/utils";

type IVerifyToken = {
  validation: boolean;
  user: Record<string, any>;
  subscriptionExpires: number;
  subscriptionPlanId: string;
  subscriptionStatus: string;
};

export const check_token = (token: string) =>
  post<IVerifyToken>({ url: "/sso/verify", body: { token } });

export const change_password = (password: string) =>
  post({ url: "/sso/change_password", body: { password } });

export const accept_terms = () => post({ url: "/sso/accept-terms" });

export const login_redirect_url = env.SERVICE_URL + "/sso/login";

export const removeAccount = () => remove({ url: "/auth/removeAccount" });

export const getIsEmailUsed = (email: string) =>
  request_fetch.get(`/check/user/${email}`, {
    projectId: env.BIG_PROXY_PROJECT_ID,
  });

  export const loginUser = (email: string, password: string) => post({ url: "/login/user", body: { email, password } });