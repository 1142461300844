import { get, post, remove } from "./api";

export const getDocuments = (query: string) => {
  return get({ url: `/documents/user/all/?${query}` });
};

export const deleteDocument = (id: string | number) =>
  remove({ url: "/documents/delete/" + id });

export const copyDocument = (id: string | number) =>
  post({ url: "/documents/copy/" + id });

export const getPdf = (body: Record<string, string | number>) => {
  return post({ url: `/generate/pdfPaymented`, body });
};

export const contactSales = (body: Record<string, string | number>) => {
  return post({ url: `/contact-sales`, body });
};
