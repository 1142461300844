/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from "react";
import cx from "classnames";

import styles from "./Option.module.scss";

export interface IOptionProps {
  id: unknown;
  isSelected: boolean;
  isHighlighted: boolean;
  option: string;
  onChange: (e: any) => void;
  valueSelector: (e: any) => string;
}

const Option: FC<IOptionProps> = ({
  id,
  isSelected,
  isHighlighted,
  option,
  onChange,
  valueSelector,
}) => {
  return (
    <button
      value={id as string}
      type="button"
      className={cx(styles.option, {
        [styles.selected]: isSelected,
        [styles.highlighted]: isHighlighted,
      })}
      onClick={() => onChange(option)}
      data-testid="option"
    >
      {valueSelector(option)}
    </button>
  );
};

export default memo(Option);
