import React, { FC } from "react";
import { useI18n } from "src/hooks";
import { Button, useClickOutsideRef } from "@package/components";

import styles from "./styles.module.scss";

interface IDocumentCreationLimit {
  onUpgrade: () => void;
  onClose: () => void;
}

const DocumentCreationLimit: FC<IDocumentCreationLimit> = ({
  onUpgrade,
  onClose,
}) => {
  const i18n = useI18n();

  const ref = useClickOutsideRef(onClose);

  return (
    <div className={styles.frame} ref={ref}>
      <div className={styles.close_container}>
        <Button.Icon
          className={styles.close}
          view={Button.Icon.views.CLOSE}
          onClick={onClose}
        />
      </div>
      <div className={styles.block}>
        <h1 className={styles.head}>{i18n.application.great_job}</h1>

        <p className={styles.description}>
          {i18n.application.used_legal_document_limit}
        </p>

        <p className={styles.warning}>{i18n.application.looks_u_need_more}</p>

        <div className={styles.buttons_bar}>
          <Button
            onClick={onUpgrade}
            className={styles.button}
            border={Button.borders.White}
            color={Button.colors.White}
          >
            {i18n.application.upgrade_now}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentCreationLimit;
