/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeEventHandler,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useKeyPress } from "../../hooks";
import { IOptionsProps } from "./Options/Options";

export const scrollToOption = (
  container: HTMLElement,
  idSelector: IOptionsProps["idSelector"],
  option: string
) => {
  const id = idSelector(option);
  const containerChildren = Array.from(container.childNodes);
  const findNode = (node: HTMLButtonElement) =>
    String(node?.value) === String(id);
  const matchedElement = containerChildren.find(
    findNode as unknown as () => boolean
  ) as HTMLElement | null;

  if (matchedElement) {
    const containerHeight = container.clientHeight;
    const elementOffsetTop = matchedElement.offsetTop;
    const elementHeight = matchedElement.clientHeight;

    matchedElement &&
      container?.scroll({
        top: elementOffsetTop + elementHeight / 2 - containerHeight / 2,
        behavior: "smooth",
      });
  }
};

export const useArrowsKeyPress = (
  containerRef: RefObject<HTMLDivElement>,
  options: any[],
  idSelector: (e: any) => unknown,
  onChange: ChangeEventHandler<HTMLInputElement>
) => {
  const [position, setPosition] = useState<number>(0);
  const optionsStorage = useRef<{ options: any[]; position: number }>({
    options: [],
    position: 0,
  });

  optionsStorage.current.options = options;
  optionsStorage.current.position = position;

  useEffect(() => {
    setPosition(0);
  }, [options]);

  const onArrowDownPress = useCallback(() => {
    const { options, position } = optionsStorage.current;
    const optionsCount = options.length - 1;
    const index = position < optionsCount ? position + 1 : optionsCount;
    setPosition(index);

    containerRef.current &&
      scrollToOption(containerRef?.current, idSelector, options[index]);
  }, [optionsStorage, setPosition, containerRef]);

  const onArrowUpPress = useCallback(() => {
    const { position } = optionsStorage.current;
    const index = position > 0 ? position - 1 : 0;
    setPosition(index);

    containerRef.current &&
      scrollToOption(containerRef?.current, idSelector, options[index]);
  }, [optionsStorage, setPosition, containerRef]);

  const onEnterPress = useCallback(() => {
    const { options, position } = optionsStorage.current;
    const option = options[position];
    if (option) {
      onChange(option);
    }
  }, [optionsStorage, onChange]);

  useKeyPress("ArrowUp", onArrowUpPress);
  useKeyPress("ArrowDown", onArrowDownPress);
  useKeyPress("Enter", onEnterPress);

  return [position, onArrowUpPress, onArrowDownPress, onEnterPress] as const;
};
