/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEventHandler, FC } from "react";
import Body from "./Body";
import Header from "./Header";
import { filteringContexts, sortingContexts } from "./contexts";
import type { ITemplate } from "./Template";
import { always } from "../../utils";

import styles from "./component.module.scss";

type IRegister = {
  template: ITemplate[];
  data: any[];
  onScrollDown?: () => void;
  onSortChange?: ChangeEventHandler;
  onFilterChange: ChangeEventHandler;
  sortValues?: Record<string, unknown>;
  filterValues: Record<string, unknown>;
  onClickHandler?: (data: any, name: string | undefined) => void;
  onClickRowHandler?: (id: string) => void;
  onDoubleClickRowHandler?: (id: string) => void;
  selectedEntities?: any[];
} & Record<string, any>;

const { Provider: SortProvider } = sortingContexts;
const { Provider: FilterProvider } = filteringContexts;

const Register: FC<IRegister> = ({
  template,
  data,
  filterValues,
  onFilterChange,
  sortValues = {},
  onSortChange = always.EMPTY_FUNC,
  onScrollDown = always.EMPTY_FUNC,
  onClickHandler,
  onClickRowHandler,
  onDoubleClickRowHandler,
  selectedEntities,
  ...rest
}) => {
  const gridTemplateColumns = template
    .map((item: ITemplate) => item.columnsRatio)
    .join(" ");

  return (
    <div className={styles.table_container}>
      <FilterProvider
        value={{ values: filterValues, onChange: onFilterChange }}
      >
        <SortProvider value={{ values: sortValues, onChange: onSortChange }}>
          <Header
            template={template}
            gridTemplateColumns={gridTemplateColumns}
            {...rest}
          />
        </SortProvider>
      </FilterProvider>
      <Body
        onClickHandler={onClickHandler}
        data={data}
        template={template}
        gridTemplateColumns={gridTemplateColumns}
        onScrollDown={onScrollDown}
        onClickRowHandler={onClickRowHandler}
        onDoubleClickRowHandler={onDoubleClickRowHandler}
        selectedEntities={selectedEntities}
        {...rest}
      />
    </div>
  );
};

export default Register;
