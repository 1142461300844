import React, { FC } from "react";
import * as views from "./views";
import cx from "classnames";

import sprite from "./icons.svg";

import styles from "./styles.module.scss";
import views_styles from "./views.module.scss";

export interface IIcon {
  /** One of "Icon.views" property */
  view: typeof views[keyof typeof views];
  className?: string;
}

const Icon: FC<IIcon> & { views: typeof views } = ({ view, className }) => {
  return (
    <svg
      key={view}
      data-testid="container"
      className={cx(styles.container, views_styles[view], className)}
    >
      <use xlinkHref={`#${view}`} />
    </svg>
  );
};

Icon.views = views;

export default Icon;

export const SpriteProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <>
    <div
      dangerouslySetInnerHTML={{ __html: sprite as unknown as string }}
      style={{ display: "none" }}
      hidden
      aria-hidden
    />
    {children}
  </>
);
