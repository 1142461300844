import styles from "./Page404.module.scss";
import React, { FC } from "react";
import { always, Button, Icon } from "@package/components";
import { useI18n } from "../../hooks";
import { ls } from "../../utils";
import { useIsomorphicLayoutEffect } from "@big-proxy/ui";
import { useDispatch } from "react-redux";
import { settings } from "../../store";
import { ITranslationKey } from "../../i18n";

const Page404: FC = () => {
  const i18n = useI18n();
  const dispatch = useDispatch();

  useIsomorphicLayoutEffect(() => {
    const savedLanguage = ls.language.get();
    if (savedLanguage) {
      dispatch(settings.actions.setLanguage(savedLanguage as ITranslationKey));
    }
  }, []);

  return (
    <div className={styles.box}>
      <Icon view={Icon.views.PAGE_404} />
      <div className={styles.page}>{i18n.page404.page_not_found}</div>
      <a href={"/"} className={styles.link}>
        <Button onClick={always.EMPTY_FUNC} className={styles.btn}>
          {i18n.page404.home_page}
        </Button>
      </a>
    </div>
  );
};

export default Page404;
