import React from "react";
import Input from "./Input";
import InputNumber from "./Input.number";
import InputArea from "./Input.area";

const Index = Input as typeof Input & {
  Area: typeof InputArea;
  Number: typeof InputNumber;
};

Index.Area = InputArea;
Index.Number = InputNumber;

export default Index;
