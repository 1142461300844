import React, { FC } from "react";
import { always, Button, Icon } from "@package/components";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import LanguagePicker from "./LanguagePicker";
import Basket from "./Basket";
import Profile from "./Profile";
import CountryAndLanguage from "./CountryAndLanguage";
import Auth from "./Auth";
import cx from "classnames";

const options = {
  language: <LanguagePicker key="language" />,
  basket: <Basket key="basket" />,
  profile: <Profile key="profile" />,
  countryAndLanguage: <CountryAndLanguage key="countryAndLanguage" />,
  auth: <Auth key="auth" />,
};

const defaultOptionsOrder = [options.language, options.basket, options.profile];

const Header: FC<{ optionsOrder?: React.ReactNode[]; sticky: boolean }> & {
  options: typeof options;
} = ({ optionsOrder = defaultOptionsOrder, sticky }) => {
  return (
    <header className={cx(styles.container, { [styles.sticky]: sticky })}>
      <Link to="/">
        <Button.Icon
          view={Icon.views.STYLED_LOGO}
          onClick={always.EMPTY_FUNC}
        />
      </Link>
      <div className={styles.right_section}>{optionsOrder}</div>
    </header>
  );
};

Header.options = options;

export default Header;
