import React from "react";
import { Link, generatePath } from "react-router-dom";
import { Icon } from "@package/components";
import profile from "src/modules/Profile";
import settings from "src/modules/Profile/Settings";

import styles from "./index.module.scss";

const generatedPath = generatePath(profile.path, { "*": settings.path });

const Profile = () => {
  return (
    <Link to={generatedPath} className={styles.link}>
      <Icon view={Icon.views.PROFIL} className={styles.icon} />
    </Link>
  );
};

export default Profile;
