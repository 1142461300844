import { get, put, post, remove } from "./api";

export const getCategories = (countryId: string, query: string) =>
  get({ url: "/categories/all/" + countryId + "?" + query });

export const updateCategory = (categoryId: string, body: Record<string, any>) =>
  put({ url: "/categories/update/" + categoryId, body });

export const createCategory = (countryId: string, body: Record<string, any>) =>
  post({ url: "/categories/add/" + countryId, body });

export const deleteCategory = (id: string) =>
  remove({ url: "/categories/remove/" + id });
