import React, { createContext, FC, ReactNode, useLayoutEffect, useState } from "react";
import { utils } from "@big-proxy/sdk";
import { useSelector } from "react-redux";
import { settings } from "../store";

import type { ITranslations as EN } from "./locales/en";
import type { ITranslations as FR } from "./locales/fr";
import type { ITranslations as DE } from "./locales/de";
import type { ITranslations as ES } from "./locales/es";
import type { ITranslations as IT } from "./locales/it";
import type { ITranslations as PL } from "./locales/pl";
import type { ITranslations as PT } from "./locales/pt";

export type ITranslations = {
  EN: EN;
  FR: FR;
  DE: DE;
  ES: ES;
  IT: IT;
  PL: PL;
  PT: PT;
};

export type ITranslationKey = keyof ITranslations;
export type ITranslationValue = ITranslations[ITranslationKey];

export const context = createContext<ITranslationValue>({} as EN);

export const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const language: ITranslationKey = useSelector(settings.selectors.getLanguage);
  const [resources, setResources] = useState<Partial<ITranslations>>({});

  const [loadedLanguage] = Object.keys(resources).reverse() as ITranslationKey[];

  useLayoutEffect(() => {
    if (!resources[language]) {
      import(`./locales/${language.toLocaleLowerCase()}`).then((module) => {
        setResources((prev) => ({ ...prev, [language]: module?.default }));
      });
    }
    utils.language.acceptedLanguage.language = language;
  }, [language]);

  const translations = (resources[language] || resources[loadedLanguage]) as ITranslationValue;

  return <context.Provider value={translations}>{translations ? children : null}</context.Provider>;
};
