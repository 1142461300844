/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  memo,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useVirtual, VirtualItem } from "react-virtual";
import { ITemplate } from "../Template";
import cx from "classnames";

import styles from "./styles.module.scss";

interface IRows extends Record<string, any> {
  data: any;
  template: ITemplate[];
  gridTemplateColumns?: string;
  onScrollDown: () => void;
  onClickHandler?: (data: any, name: string | undefined) => void;
  onClickRowHandler?: (id: string) => void;
  onDoubleClickRowHandler?: (id: string) => void;
  selectedEntities?: any[];
}

interface IRow extends Record<string, any> {
  data: any;
  template: ITemplate[];
  onClickHandler?: (data: any, name: string | undefined) => void;
}

const Row: FC<IRow> = ({ template, data, onClickHandler, ...rest }) => {
  const onClick: MouseEventHandler = (event) => {
    const { name } = event.target as HTMLButtonElement;
    onClickHandler?.(data, name);
  };

  return (
    <>
      {template.map((_template) => {
        return (
          <div key={`row-${_template.valueKey}`} className={styles.list_items}>
            {_template.valueComponent ? (
              _template.valueComponent?.({
                ...rest,
                name: _template.valueKey,
                onClick,
                value: data,
                className: styles.list_item,
              })
            ) : (
              <span className={styles.list_item}>
                {data[_template.valueKey]}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
};

const Rows: FC<IRows> = ({
  data,
  template,
  gridTemplateColumns,
  onScrollDown,
  onClickHandler,
  onClickRowHandler,
  onDoubleClickRowHandler,
  selectedEntities = [],
  ...rest
}) => {
  const parentRef = React.useRef<HTMLInputElement>(null);

  const selectedEntitiesStore = useRef<any[]>([]);
  selectedEntitiesStore.current = selectedEntities;

  const scrollHandler = (event: Event) => {
    const target = event.target as HTMLDivElement;
    target.scrollHeight - (target.scrollTop + target.clientHeight) < 100 &&
      onScrollDown();
  };

  useEffect(() => {
    parentRef.current?.addEventListener("scroll", scrollHandler);
    return function () {
      parentRef.current?.removeEventListener("scroll", scrollHandler);
    };
  }, [parentRef]);

  const rowsVirtual = useVirtual({
    size: data.length,
    parentRef,
    overscan: 5,
  });

  const onClick = useCallback(
    (data: React.MouseEvent<HTMLDivElement>) => {
      const id = data.currentTarget.getAttribute("data-value");
      onClickRowHandler && onClickRowHandler(id as string);
    },
    [onClickRowHandler]
  );

  const onDoubleClick = useCallback(
    (data: React.MouseEvent<HTMLDivElement>) => {
      const id = data.currentTarget.getAttribute("data-value");
      onDoubleClickRowHandler && onDoubleClickRowHandler(id as string);
    },
    [onClickRowHandler]
  );

  return (
    <div ref={parentRef} className={styles.register}>
      <div
        className={styles.register_wrapper}
        style={{
          height: rowsVirtual.totalSize,
        }}
      >
        {rowsVirtual.virtualItems.map((virtualRow: VirtualItem) => {
          return (
            <div
              key={data[virtualRow.index].id}
              ref={virtualRow.measureRef}
              className={cx(styles.register_list, {
                [styles.register_list_bg]: virtualRow.index % 2,
                [styles.active]: selectedEntitiesStore.current.includes(
                  String(data[virtualRow.index].id)
                ),
              })}
              data-value={data[virtualRow.index].id}
              style={{
                gridTemplateColumns: gridTemplateColumns,
                transform: `translateY(${virtualRow.start}px)`,
              }}
              onClick={onClick}
              onDoubleClick={onDoubleClick}
            >
              <Row
                onClickHandler={onClickHandler}
                template={template}
                data={data[virtualRow.index]}
                {...rest}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(Rows);
