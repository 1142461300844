import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import Button from "../Button";
import Icon, { IIcon } from "../Icon";
import { useDimensions } from "../../hooks";
import { animated, useTransition } from "react-spring";

import styles from "./Swiper.module.scss";

export type ISwiper = {
  data: Array<any>;
  component: FC<{ content: any } & Record<string, any>>;
  firstActiveSlide?: number;
  step?: number;
  minComponentWidth?: number;
  spaceBetween?: number;
  switchingIcon?: IIcon["view"];
} & Record<string, any>;

type IState = {
  min: number;
  max: number;
  items: JSX.Element[];
};

const initialState: IState = {
  min: 0,
  max: 0,
  items: [],
};

const MIN = 0;

const countCurrentMax = (
  activeSlide: number,
  max: number,
  slidesQuantity: number
) => {
  if (max <= slidesQuantity) return max;

  return activeSlide + slidesQuantity <= max
    ? activeSlide + slidesQuantity
    : (activeSlide + slidesQuantity) % max;
};

const visibleComponents = (
  allSlides: JSX.Element[],
  min: number,
  max: number
) => {
  return max > min
    ? allSlides.slice(min, max)
    : allSlides.slice(min).concat(allSlides.slice(0, max));
};

const Swiper: FC<ISwiper> = ({
  data = [],
  component: Component,
  firstActiveSlide = 0,
  step = 1,
  minComponentWidth = 150,
  spaceBetween = 10,
  switchingIcon,
  ...componentProperties
}) => {
  const MAX = useMemo(() => data.length, [data]);

  const _firstActiveSlide =
    firstActiveSlide >= MIN && firstActiveSlide < MAX ? firstActiveSlide : MIN;

  const _step = step > 0 && step < MAX ? step : 1;

  const allSlides = useMemo(() => {
    return data.map((elem, i) => (
      <div
        key={i}
        style={{
          minWidth: `${minComponentWidth}px`,
        }}
        className={styles.component}
      >
        <Component content={elem} {...componentProperties} />
      </div>
    ));
  }, [data, spaceBetween, minComponentWidth, Component, componentProperties]);

  const [ref, bounds] = useDimensions();
  const slidesQuantity = useMemo(
    () => Math.floor(bounds.width / (minComponentWidth + spaceBetween)),
    [bounds.width]
  );
  const isWithoutSwitching = slidesQuantity >= MAX;

  const [currentSlides, setCurrentSlides] = useState(initialState);

  useEffect(() => {
    if (slidesQuantity) {
      setCurrentSlides({
        min: _firstActiveSlide,
        max: countCurrentMax(_firstActiveSlide, MAX, slidesQuantity),
        items: visibleComponents(
          allSlides,
          _firstActiveSlide,
          countCurrentMax(_firstActiveSlide, MAX, slidesQuantity)
        ),
      });
    }
  }, [slidesQuantity, MAX]);

  const transitions = useTransition(currentSlides.items, {
    from: () => ({
      position: "absolute" as const,
      opacity: 0,
    }),
    enter: () => ({
      position: "static" as const,
      opacity: 1,
    }),
  });

  const onClickLeftArrow = useCallback(() => {
    const currentMin =
      currentSlides.min - _step >= MIN
        ? currentSlides.min - _step
        : MAX + (currentSlides.min - _step);

    const currentMax =
      currentSlides.max - _step > MIN
        ? currentSlides.max - _step
        : MAX + (currentSlides.max - _step);

    setCurrentSlides({
      min: currentMin,
      max: currentMax,
      items: visibleComponents(allSlides, currentMin, currentMax),
    });
  }, [setCurrentSlides, currentSlides, _step]);

  const onClickRightArrow = useCallback(() => {
    const currentMin =
      currentSlides.min + _step < MAX
        ? currentSlides.min + _step
        : (currentSlides.min + _step) % MAX;

    const currentMax =
      currentSlides.max + _step <= MAX
        ? currentSlides.max + _step
        : (currentSlides.max + _step) % MAX;

    setCurrentSlides({
      min: currentMin,
      max: currentMax,
      items: visibleComponents(allSlides, currentMin, currentMax),
    });
  }, [setCurrentSlides, currentSlides, MAX, _step]);

  return (
    <div className={styles.container}>
      <Button.Icon
        view={switchingIcon || Icon.views.RIGHT_ARROW}
        className={cx(styles.arrow_left, {
          [styles.disable_switch]: isWithoutSwitching,
        })}
        onClick={onClickLeftArrow}
        disabled={isWithoutSwitching}
      />
      <div className={styles.items} ref={ref}>
        <div
          className={styles.item}
          style={{
            gap: `${(bounds.width - (minComponentWidth * slidesQuantity)) / slidesQuantity}px`,
            padding: `${spaceBetween * 2}px 0`,
            justifyContent: 'center'
          }}
        >
          {transitions((styles, item) => (
            <animated.div style={styles}>{item}</animated.div>
          ))}
        </div>
      </div>
      <Button.Icon
        view={switchingIcon || Icon.views.RIGHT_ARROW}
        className={cx(styles.arrow_right, {
          [styles.disable_switch]: isWithoutSwitching,
        })}
        onClick={onClickRightArrow}
        disabled={isWithoutSwitching}
      />
    </div>
  );
};

export default Swiper;
