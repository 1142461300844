/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject, useEffect, useRef } from "react";
import { always } from "../utils";

type AnyEvent = MouseEvent | TouchEvent;

type Handler = (event: AnyEvent) => void;

type Exclude = RefObject<any>[];

export const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  excludes: Exclude = always.EMPTY_ARRAY as unknown as RefObject<any>[]
): void => {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;

      if (
        !el ||
        el.contains(event.target as Node) ||
        excludes.some((ref) => ref.current.contains(event.target))
      )
        return;

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [ref, handler, excludes]);
};

export const useClickOutsideRef = <
  T extends HTMLElement = HTMLDivElement,
  F extends Handler = Handler
>(
  handler: F,
  excludes?: Exclude
): RefObject<T> | null => {
  const ref = useRef(null);
  useClickOutside(ref, handler, excludes);
  return ref;
};

export default useClickOutside;
