import React from "react";
import Button from "./Button";
import ButtonIcon from "./Button.icon";

const Index = Button as typeof Button & {
  Icon: typeof ButtonIcon;
};

Index.Icon = ButtonIcon;

export default Index;
