import createStore from "./middlewares/createStore";

import { store as settings } from "./settings";
import { store as authorization } from "./authorization";

export * as settings from "./settings";
export * as authorization from "./authorization";

const staticReducers = {
  [authorization.name]: authorization.reducer,
  [settings.name]: settings.reducer,
};

const store = createStore(staticReducers);

export default store;
