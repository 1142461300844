import React from "react";
import styles from "./LanguagePicker.module.scss";
import { ITranslationKey } from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "src/store";

type ILanguage = { id: ITranslationKey; label: string }[];
const languages: ILanguage = [
  // TODO: update to use from .env
  { id: "EN", label: "EN" },
  { id: "FR", label: "FR" },
  { id: "DE", label: "DE" },
];

const LanguagePicker = () => {
  const dispatch = useDispatch();
  const languageId = useSelector(settings.selectors.getLanguage);

  const languageOption = languages.find(({ id }) => id === languageId);

  const onSelect = (event: unknown) => {
    const { value } = (event as { target: { value: ITranslationKey } }).target;
    dispatch(settings.actions.setLanguage(value));
  };

  return (
    <div className={styles.languages}>
      <button className={styles.selected} type="button">
        {languageOption?.label}
      </button>
      <div className={styles.options}>
        {languages
          .filter(({ id }) => id !== languageId)
          .map(({ id, label }) => (
            <button
              className={styles.option}
              key={id}
              value={id}
              onClick={onSelect}
            >
              {label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default LanguagePicker;
