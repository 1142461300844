import React from "react";
import Select from "./Select";
import MultiSelect from "./Select.multi";

const Index = Select as typeof Select & {
  Multi: typeof MultiSelect;
};

Index.Multi = MultiSelect;

export default Index;
