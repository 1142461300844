import { BIG_PROXY_CLIENT_URL, BIG_PROXY_PROJECT_ID } from "./env";

const DEFAULT_LANGUAGE = "EN";

export const getDefaultGetLoginParams = () => ({
  successURL: window.location.href,
  successURLHash: "",
  failureURL: window.location.href,
  language: DEFAULT_LANGUAGE,
  destination: "",
});

export const getLoginUrl = (
  _params: {
    successURL?: string;
    successURLHash?: string;
    failureURL?: string;
    language?: string;
    destination?: string;
  } = getDefaultGetLoginParams()
) => {
  const params = { ...getDefaultGetLoginParams(), ..._params };
  const successURL = params.successURL || window.location.href;

  let language = params.language || DEFAULT_LANGUAGE;
  if (language.length < 3) {
    language = language.toLowerCase();
  }

  const search = new URLSearchParams({
    successURL: successURL + params.successURLHash,
    failureURL: params.failureURL || successURL,
    language,
    projectId: String(BIG_PROXY_PROJECT_ID),
  });

  return `${BIG_PROXY_CLIENT_URL}${params.destination}?${search}`;
};

export type IServerResponse<T> = { error: boolean; message: T; meta: unknown };
export type IFetchResponse<R> = { error: boolean; message: any };

export const goToLoginPage = (_camebackUrl?: string, _failureUrl?: string) => {
  const camebackUrl = _camebackUrl || window.location.href
  const failureUrl = _failureUrl || window.location.origin + '/'
  const lang = navigator.language
  const urlLogin = `${BIG_PROXY_CLIENT_URL}/login?noReg=true&successURL=${camebackUrl}&failureURL=${failureUrl}&language=${lang}&projectId=1&ccr=true`
  window.location.href = urlLogin
}

type R = any
type ICheckAutn = {
  verifyToken: () => Promise<IFetchResponse<IServerResponse<any>>>;
  camebackUrl?: string,
  failureUrl?: string
}
export const checkAuth = async (p: ICheckAutn): Promise<IFetchResponse<R> | void> => {
  const urlQueries = new URLSearchParams(window.location.search)
  const jwtOut = urlQueries.get('token')
  if (jwtOut) {
    localStorage.setItem('token', jwtOut)
    const url = new URL(window.location.href);
    url.searchParams.delete('token');
    window.history.replaceState({}, document.title, url.toString());
  }

  const jwtLocal = localStorage.getItem('token')
  const is = jwtLocal && await p.verifyToken()

  if (!jwtLocal || (is && is?.error)) {
    localStorage.setItem('token', '')
    goToLoginPage()
    return
  }

  return is as IFetchResponse<R>;
}
