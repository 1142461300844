import React, { FC, useEffect } from "react";
import { downloads, ls } from "src/utils";
import * as services from "src/service";
import { useIsomorphicLayoutEffect, WindowBridge } from "@big-proxy/ui";

const AuthRedirect = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    ls.token.set(searchParams.get("token"));
    window.location.href = window.location.origin;
  }, []);

  return <></>;
};

const StripeCardSetup = () => {
  useIsomorphicLayoutEffect(() => {
    const query = Object.fromEntries(
      Array.from(new URLSearchParams(window.location.search).entries())
    );

    new WindowBridge({
      onReady: (bridge) => {
        bridge.emit("SETUP_COMPETE", query);
        bridge.on("CLOSE_WINDOW", () => {
          window.close();
        });
      },
    });
  }, []);

  return <></>;
};

export const REQUIRED_QUERY_PARAMS_PAYMENT = {
  ids: "ids",
  userId: "userId",
  sessionId: "sessionId",
};
const PaymentSuccessRedirect = () => {
  useEffect(() => {
    const f = async () => {
      const searchParams = new URLSearchParams(window.location.search);

      const userId = searchParams.get(REQUIRED_QUERY_PARAMS_PAYMENT.userId) as string;

      const ids = JSON.parse(searchParams.get(REQUIRED_QUERY_PARAMS_PAYMENT.ids) as string).join(",");

      const secret = searchParams.get(REQUIRED_QUERY_PARAMS_PAYMENT.sessionId) as string;

      const paymentResponse = await services.basket.setPaymentDocuments(userId, ids, secret);

      if (!paymentResponse.error) {
        const { message } = paymentResponse;
        await downloads.pdf(message.base64.file, message.base64.docName, message.base64.type)
      }

      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 300);
    };
    f();
  }, []);

  return <></>;
};

export const subCallbacks = {
  login: {
    path: "/login/",
    element: <AuthRedirect />,
  },
  basket_pay: {
    path: "/payment-success/",
    element: <PaymentSuccessRedirect />,
  },
  stripe_card_setup: {
    path: "/stripe-card-setup/",
    element: <StripeCardSetup />,
  },
};

export const callbacks = {
  path: "/callback",
  children: Object.values(subCallbacks),
};

const CallbackProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const pathList = callbacks.children.map(({ path }) => [`${callbacks.path}${path}`, path]).flat();

  if (pathList.includes(window.location.pathname)) {
    const component = callbacks.children.find(({ path }) =>
      window.location.pathname.includes(path)
    );

    return component?.element || <></>;
  }

  return <>{children}</>;
};

export default CallbackProvider;
