import React, { useEffect } from "react";
import styles from "./CountryAndLanguage.module.scss";
import CountryAndLanguageMobile from "./CountryAndLanguageMobile";
import { useResize } from "src/hooks/useResize";
import { ITranslationKey, ITranslations } from "../../i18n";
import { useSelector, useStore } from "react-redux";
import { settings } from "src/store";
import { ls } from "src/utils";
import { useCountryLanguageResource, resourceSelectors } from "../../hooks";
import * as service from "src/service";
import { ICountryLanguageResource } from "../../hooks/useResource/useCountryLanguageResource";
import { Icon } from "@package/components";
import { countryFlags } from "./utils/constants";
import { Update } from "@big-proxy/sdk";

const currentLanguage = window.navigator.language.toUpperCase();

const CountryAndLanguage = () => {
  const { getState, dispatch } = useStore();
  const { innerWidth } = useResize();
  const countryId = useSelector(settings.selectors.getCountryId);
  const languageId = useSelector(settings.selectors.getLanguage);
  const { entities, onLoad, store } = useCountryLanguageResource();

  useEffect(() => {
    const state = getState();

    const isLoaded = resourceSelectors.getIsLoaded(state, store.name);

    const f = async () => {
      const loaderCountryLanguage = onLoad();

      const {
        data: { countryCode: currentCodeCountry },
      } = await service.foreign_services.getLocationInfo();
      await loaderCountryLanguage;

      const entries: ICountryLanguageResource[] = resourceSelectors.getEntries(
        getState(),
        store.name
      );

      const cachedCountryId = ls.countryId.get();

      const cachedCountry = entries.find(
        ({ countryId }) => String(cachedCountryId) === String(countryId)
      );
      const currentCountry = entries.find(({ codeCountry }) => codeCountry === currentCodeCountry);
      const defaultCountry = entries.find(({ codeCountry }) => codeCountry === "CH");

      const pickedCodeCountry =
        cachedCountry?.countryId ??
        currentCountry?.countryId ??
        defaultCountry?.countryId ??
        entries?.[0]?.countryId;
      const cachedLanguage = ls.language.get();

      const defaultLanguage = entries.find(({ countryId }) => countryId === pickedCodeCountry);
      const browserLanguage = defaultLanguage?.children.includes(currentLanguage);
      const language = browserLanguage ? currentLanguage : defaultLanguage?.children?.[0];

      const pickedLanguage = (cachedLanguage ?? language) as keyof ITranslations;

      dispatch(settings.actions.setCountryId(Number(pickedCodeCountry)));
      dispatch(settings.actions.setLanguage(pickedLanguage));
    };
    !isLoaded && f();
  }, [dispatch]);

  const countriesOption = entities.find(({ countryId: cid }) => cid === countryId);
  const countriesOptions = entities.filter(({ countryId: cid }) => cid !== countryId);
  const languagesOption = (countriesOption?.childrenEntities || []).find(
    ({ short }) => String(short) === String(languageId)
  );

  const languagesOptions = (countriesOption?.childrenEntities || []).filter(
    ({ short }) => String(short) !== String(languageId)
  );

  const onSelectCountry = (event: unknown) => {
    const { value } = (event as { target: { value: string } }).target;
    const numberValue = Number(value);

    ls.countryId.set(numberValue);

    dispatch(settings.actions.setCountryId(numberValue));

    const country = entities.find(({ countryId }) => String(countryId) === String(value));
    const countryLanguage = country?.children?.includes(currentLanguage);
    const setLanguage = countryLanguage ? currentLanguage : country?.children?.[0];
    ls.language.set(setLanguage);
    dispatch(settings.actions.setLanguage(setLanguage as keyof ITranslations));
  };

  const onSelectLanguage = (event: unknown) => {
    const { value } = (event as { target: { value: ITranslationKey } }).target;
    ls.language.set(value);
    dispatch(settings.actions.setLanguage(value));
    Update.language(value);
  };

  return (
    <>
      {innerWidth && innerWidth >= 991 ? (
        <>
          <div className={styles.countries}>
            <button className={styles.selected} type="button">
              <Icon view={Icon.views.GLOBE} />
              <p className={styles.text}>{countriesOption?.countryName.EN}</p>
            </button>
            <div className={styles.options}>
              {countriesOptions.map(({ countryName, countryId }) => (
                <div key={countryId} className={styles.optionsWrapper}>
                  <div>{countryFlags[countryName.EN]}</div>
                  <button className={styles.option} value={countryId} onClick={onSelectCountry}>
                    {countryName.EN}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.languages} aria-disabled={languagesOptions.length === 0}>
            <button className={styles.selected} type="button">
              <p className={styles.text}>{languagesOption?.short}</p>
            </button>
            <div className={styles.options}>
              {languagesOptions.map(({ short }) => (
                <button
                  className={styles.option}
                  key={short}
                  value={short}
                  onClick={onSelectLanguage}
                >
                  {short}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : (
        <CountryAndLanguageMobile
          countriesOption={countriesOption}
          countriesOptions={countriesOptions}
          languagesOption={languagesOption}
          languagesOptions={languagesOptions}
          onSelectCountry={onSelectCountry}
          onSelectLanguage={onSelectLanguage}
        />
      )}
    </>
  );
};

export default CountryAndLanguage;
